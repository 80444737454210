import { useState } from "react";

interface ToggleSwitchType {
  setSwitchValue: (state: string) => void;
  switchValues: string[];
  currentValue: string;
}

const ToggleSwitch = ({ setSwitchValue, switchValues, currentValue }: ToggleSwitchType) => {
  const [active, setActive] = useState(currentValue);

  const handleSwitch = (filter: string) => {
    setActive(filter);
    setSwitchValue(filter);
  };
  return (
    <div
      className={`w-[237px] h-9 p-1 bg-[#f9f9f9] rounded-[10px] flex justify-start items-center uppercase`}>
      <div
        className={`w-1/2 h-7 pl-2 pr-[7.50px] py-1.5 rounded-md flex justify-center items-center cursor-pointer ${
          active === switchValues[0] ? "bg-white border" : ""
        }`}
        onClick={() => handleSwitch(switchValues[0])}>
        <div
          className={`w-1/2 h-4 text-center text-[13px] font-medium font-['Archivo'] leading-tight ${
            active === switchValues[0] ? "text-[#0d0d0d]" : "text-[#0d0d0d]/60"
          }`}>
          {switchValues[0]}
        </div>
      </div>
      <div
        className={`w-1/2 h-7 pl-2 pr-[7.50px] py-1.5 rounded-md flex justify-center items-center cursor-pointer ${
          active === switchValues[1] ? "bg-white border" : ""
        }`}
        onClick={() => handleSwitch(switchValues[1])}>
        <div
          className={`w-full h-4 text-center text-[13px] font-medium font-['Archivo'] leading-tight ${
            active === switchValues[1] ? "text-[#0d0d0d]" : "text-[#0d0d0d]/60"
          }`}>
          {switchValues[1]}
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
