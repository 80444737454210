import { useState } from "react";
import { TableProps } from "./types";
import { Checkbox } from "antd";
import "./Table.scss";
import { CheckboxChangeEvent } from "antd/es/checkbox";

function Table<TField, TData = TField>({
  checked = false,
  tableData,
  fields,
  builder,
  isLoading = false,
  columns,
  rows,
  after,
  noDataMessage = "No records found to display",
  handleRowClick,
  startColumn = 0,
  startRow = 0,
}: TableProps<TField, TData & { id?: string | Number }>) {
  const numberOfColumns = columns || fields.length;
  const numberOfRows = tableData?.length > 0 ? rows || tableData?.length : 0;
  const [isChecked] = useState<boolean>(checked);
  const [selected, setSelected] = useState<any[]>([]);

  // Slice the table data based on startRow and number of rows
  const displayedData = tableData?.slice(startRow, startRow + numberOfRows);

  const handleSelectAllClick = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n, i) => n.id!);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChange = (event: CheckboxChangeEvent, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className="w-full">
      {/* {isLoading && tableData.length === 0  ? <TableShimmer /> :
     
     tableData.length === 0 && !isLoading ? <div className="no-data-container">
      <p>{noDataMessage}</p>

     </div> : */}
      <table className="table">
        <thead className="table-header-container rounded-[10px]">
          <tr>
            {isChecked && (
              <th>
                <Checkbox
                  checked={
                    tableData.length > 0 && selected.length === tableData.length
                  }
                  onChange={handleSelectAllClick}
                />
                {/* <input
                  type="checkbox"
                  name="check"
                  className="checkbox"
                  checked={tableData.length > 0 && selected.length === tableData.length}
                  onChange={handleSelectAllClick}
                /> */}
              </th>
            )}
            {fields.map((data) => (
              <th scope="col" key={Math.random().toString()}>
                {data.displayName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="large-table-body">
          {isLoading && tableData.length === 0 ? (
            <tr>
              <td className="single-empty-row"></td>
              <p className="no-data-container">
                ....loading
              </p>
              {/* <TableShimmer rows={5} columns={4} />
              <TableShimmer rows={5} columns={4} /> */}
            </tr>
          ) : tableData.length === 0 && !isLoading ? (
            <tr>
              <td className="single-empty-row"></td>
              <div className="no-data-container">
                <p>No data available</p>
              </div>
            </tr>
          ) : (
            displayedData?.map((item, i) => (
              <tr
                key={i}
                className="table-body-container"
                onClick={() => handleRowClick?.(item)}>
                {isChecked && (
                  <td>
                    <Checkbox
                      checked={
                        tableData.length > 0 &&
                        selected.length === tableData.length
                      }
                      onChange={(event) =>
                        handleChange(event, item.id as unknown as any)
                      }
                    />
                    {/* <input
                      type="checkbox"
                      onChange={(event) => handleChange(event, item.id as unknown as any)}
                      checked={selected.indexOf(item.id as unknown as any) !== -1}
                      name="check"
                      className="checkbox"
                    /> */}
                  </td>
                )}
                {fields.map((field, j) => (
                  <td key={j}>{builder(field, item, i, j)}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
