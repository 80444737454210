import React, { useState } from "react";
import LandingLayout from "../../components/LandingLayout/LandingLayout";
import PrimaryButton from "../../components/buttons/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/UIKits/CustomInput/CustomInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../services/auth";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import "./ForgotPasword.scss"
import { Spinner } from "../../components/UIKits/Spinner/Spinner";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Enter valid email")
    .required("Email is a required field"),
});

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false); // Add state for success
  const [userEmail, setUserEmail] = useState("");

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const { mutateAsync: initiatePwdReset, isLoading } = useMutation(
    forgotPassword,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setUserEmail(watchedEmail); // Store the user's email
        setIsSuccess(true); // Set success state to true
        resetForm();
      },
      onError: (error: AxiosError) => {
        // @ts-ignore
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (data: any) => {
    initiatePwdReset(data);
  };

  const handleResend = () => {
    initiatePwdReset({ email: userEmail }); // Resend the reset email
  };

  const handleTryDifferentEmail = () => {
    setIsSuccess(false); // Hide success message
    resetForm(); // Reset the form to re-enter a new email
  };
  const watchedEmail = watch("email", "");

  return (
    <LandingLayout>
      <div className="w-[400px] h-[322px] flex-col justify-start items-start gap-10 inline-flex m-auto">
        {isSuccess ? (
          <div>
            <div className="text-stone-950 text-[32px] font-semibold m-6 ml-0">
              Check your mail
            </div>
            <div className="w-[400px] text-[#6A6A6A] text-base font-medium succesMessage">
              Thanks! If {userEmail} matches an email we have on file, then
              we've sent you an email containing further instructions for
              resetting your password. 
              <br />
              <br />
              If you haven't received an email in 5
              minutes, check your spam,{" "}
              <button onClick={handleResend} className="text-[#5f38fa]">
                resend
              </button>
              , or{" "}
              <button
                onClick={handleTryDifferentEmail}
                className="text-[#5f38fa]"
              >
                try a different email
              </button>
              .
            </div>
            <div
              className="text-center mt-4 text-[#5f38fa] text-sm font-semibold font-['Uncut Sans Variable'] leading-tight cursor-pointer"
              onClick={() => (window.location.href = "/login")}
            >
              Return to sign in
            </div>
          </div>
        ) : (
          <div>
            <div className="text-stone-950 text-[32px] font-semibold font-['Archivo']">
                Forgot your password?
            </div>
            <div className="w-[400px] text-[#6A6A6A] text-base font-medium mt-4">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col justify-start items-start gap-[2px] mx-auto mt-10">
                <div className="w-full mb-3">
                  <CustomInput
                    type="email"
                    name="email"
                    value={watchedEmail}
                    label="Email address"
                    placeholder="name@email.com"
                    control={control}
                    errorMessage={errors.email?.message}
                  />
                </div>
              </div>

              <div className="mt-8 text-center">
                <PrimaryButton
                  label={
                    isLoading ? (
                        <Spinner/>
                    ) : (
                      "Continue"
                    )
                  }
                  type="submit"
                  width="100%"
                />
                <div
                  className="text-center mt-4 text-[#5f38fa] text-sm font-semibold font-['Uncut Sans Variable'] leading-tight cursor-pointer"
                  onClick={() => (window.location.href = "/login")}
                >
                  Return to sign in
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </LandingLayout>
  );
};

export default ForgotPassword;
