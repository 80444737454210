import { useEffect, useState } from "react";
import RevenueBreakDownChart from "../charts/RevenueBreakDownChart/RevenueBreakDownChart";
import Heading from "../Heading/Heading";
import { GraphPayloadDataType } from "./types";
import {
  formatCurrency,
  getPercentageContributionToTotal,
  separateAndCapitalize,
} from "../../utils/helper";
import CurrencyRadioSelector from "../CurrencyRadioSelector/CurrencyRadioSelector";
import OutlineButton from "../buttons/OutlineButton/OutlineButton";
import RevenueBreakDownPopup from "../RevenueBreakDownPopup/RevenueBreakDownPopup";
import { DateFilterType } from "../../pages/Home/types";
import { useQuery } from "react-query";
import { getAirlineRevenue } from "../../services/home";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";

import "./RevenueBreakDownCard.scss";
import { toast } from "react-toastify";

const RevenueBreakDownCard = ({
  dateFilter,
}: {
  dateFilter: DateFilterType;
}) => {
  const [currency, setCurrency] = useState("naira");
  const handleCurrencySwitch = (value: string) => {
    if (value === "dollar") {
      setActiveStack(dollarSummaryData);
    } else {
      setActiveStack(nairaSummaryData);
    }
    setCurrency(value);
  };

  const {
    isLoading,
    data: airlineRevenue,
    isError,
  } = useQuery(
    [
      "airlineRevenue",
      {
        dateFilter,
      },
    ],
    () =>
      getAirlineRevenue({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );

  const nairaSummaryData = !isLoading
    ? [
        {
          name: "TscDom",
          value: airlineRevenue?.data?.tscDomNaira?.amount + "",
        },
        {
          name: "TscInt",
          value: airlineRevenue?.data?.tscIntlNaira?.amount + "",
        },
        {
          name: "PscDom",
          value: airlineRevenue?.data?.pscDomestic?.amount + "",
        },
        { name: "PscReg", value: "0" },
      ]
    : [];
  const dollarSummaryData = !isLoading
    ? [
        {
          name: "TscDom",
          value: airlineRevenue?.data?.tscDomDollar?.amount + "",
        },
        {
          name: "TscInt",
          value: airlineRevenue?.data?.tscIntlDollar?.amount + "",
        },
        { name: "PscDom", value: "0" },
        {
          name: "PscReg",
          value: airlineRevenue?.data?.pscRegional?.amount + "",
        },
      ]
    : [];

  const [activeStack, setActiveStack] = useState<GraphPayloadDataType>([]);
  const numberList =
    activeStack?.map((element) => parseFloat(element.value)) || [];

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isLoading && currency === "naira") {
      setActiveStack(nairaSummaryData);
    } else setActiveStack(dollarSummaryData);
  }, [isLoading, currency]);

  return (
    <div className="revenue-breakdown-card">
      <div className="summary-card">
        <Heading
          isSectionHeading={true}
          headingText={"Revenue breakdown per airport"}
          headingToolTipText="GMV revenue is based on data from NCAA TSC Domestic."
        />
        {isLoading ? null : airlineRevenue?.data.length === 0 ? (
          <NoDataIndicator />
        ) : isError ? null : (
          <>
            <div className="currency-toggle">
              <CurrencyRadioSelector
                currency={currency}
                setCurrency={handleCurrencySwitch}
              />
            </div>
            <div className="w-full box-border">
              <p className="summary-text">Summary</p>
              <div className="contribution-bar">
                {!isLoading &&
                  activeStack?.map((element, id) => (
                    <span
                      key={id}
                      className={`marker marker${id}`}
                      style={{
                        width: `${getPercentageContributionToTotal(
                          numberList,
                          parseFloat(element.value)
                        )}%`,
                      }}></span>
                  ))}
              </div>
              <div className="data-points ">
                {activeStack?.map((element, id) => (
                  <div className={"data-info justify-between"} key={id}>
                    <div className="data-name">
                      <div className={`marker marker${id}`}></div>
                      <span className="name">
                        {separateAndCapitalize(element?.name)
                          .slice(0, 6)
                          .toUpperCase() +
                          separateAndCapitalize(element?.name)
                            .slice(6)
                            .toLowerCase()}
                      </span>
                    </div>
                    <span className="data-value">
                      {formatCurrency(parseFloat(element.value), currency)}{" "}
                      <span className="percentage-contribution">
                        {`(${getPercentageContributionToTotal(
                          numberList,
                          parseFloat(element.value)
                        )}%)`}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="w-[60%] flex flex-col">
        <div className="w-full flex justify-end pr-3 pt-3">
          <OutlineButton
            label={"View more"}
            className="!px-3 !py-1.5 z-50"
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="w-full h-full">
          {isLoading ? (
            <DataLoadingIndicator />
          ) : isError ? (
            <NoDataIndicator customText="An error occurred, please try again later!" />
          ) : (currency === "naira" &&
              airlineRevenue?.data?.airportRevenues[0]?.values.length === 0) ||
            (currency === "dollar" &&
              airlineRevenue?.data?.airportRevenues[1]?.values.length === 0) ? (
            <NoDataIndicator />
          ) : (
            <div className="w-full h-full">
              <RevenueBreakDownChart
                chartData={
                  currency === "naira"
                    ? airlineRevenue?.data?.airportRevenues[0]?.values
                    : airlineRevenue?.data?.airportRevenues[1]?.values
                }
                currency={currency}
              />
            </div>
          )}
        </div>
      </div>
      <RevenueBreakDownPopup
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default RevenueBreakDownCard;
