import {
  formatCurrency,
  getPercentageContributionToTotal,
  separateAndCapitalize,
} from "../../../../utils/helper";
import moment from "moment";
import { GraphPayloadDataType } from "../../../RevenueBreakDownCard/types";

import "./CustomToolTip.scss";
import { useEffect } from "react";
interface CustomTooltipPropsType {
  active: boolean;
  payload: GraphPayloadDataType;
  label: string;
  currency: string;
  showDataName?: boolean;
  setActiveStack?: (activeStack: GraphPayloadDataType) => void;
  showTotal?: boolean;
  activeStack?: GraphPayloadDataType;
  customNames?: string[];
  dataPointColors?: string[];
}

const CustomToolTip = ({
  active,
  payload,
  label,
  currency,
  showDataName = true,
  setActiveStack,
  showTotal = false,
  activeStack,
  customNames,
  dataPointColors,
}: CustomTooltipPropsType) => {
  // A helper function to compare arrays of objects (payload and activeStack)
  const isPayloadEqualToActiveStack = (
    payload1: GraphPayloadDataType,
    payload2: GraphPayloadDataType = []
  ) => {
    if (!payload1 || !payload2 || payload1.length !== payload2.length)
      return false;
    return payload1.every(
      (p, index) =>
        p.name === payload2[index].name && p.value === payload2[index].value
    );
  };

  // Use useEffect to set the active stack only when payload changes
  useEffect(() => {
    if (
      active &&
      setActiveStack &&
      payload &&
      !isPayloadEqualToActiveStack(payload, activeStack)
    ) {
      setActiveStack(payload);
    }
  }, [active, payload, setActiveStack, activeStack]);

  if (active && payload?.length) {
    const numberList = payload?.map((element) => parseFloat(element.value));
    return (
      <div className="custom-tooltip">
        <p className="title">
          {customNames && dataPointColors ? (
            <span className="flex items-center gap-1.5">
              <span
                className="marker inline-flex h-2.5 w-2.5 rounded-sm"
                style={{
                  backgroundColor:
                    dataPointColors[
                      payload[0]?.payload?.name
                        ? customNames.indexOf(payload[0]?.payload?.name)
                        : 0
                    ],
                }}></span>
              {payload[0]?.payload?.name}
            </span>
          ) : !showDataName ? (
            moment(label, "YYYY-M").format("MMM").toUpperCase()
          ) : (
            label
          )}
        </p>
        <div className="data-points">
          {payload?.map((element, id) => (
            <div
              className={`data-info ${showDataName ? "justify-between" : ""}`}
              key={id}>
              <div className="data-name">
                {customNames && dataPointColors ? (
                  <span>
                    <span className="capitalize font-medium text-[#0D0D0D] mr-1.5">
                      {element?.name}:{" "}
                    </span>
                  </span>
                ) : (
                  <>
                    <div
                      className={`marker marker${id} ${
                        showDataName ? "" : "ash-bg"
                      }`}></div>
                    {showDataName ? (
                      <span className="name">
                        {separateAndCapitalize(element?.name)
                          .slice(0, 6)
                          .toUpperCase() +
                          separateAndCapitalize(element?.name)
                            .slice(6, 8)
                            .toLowerCase()}
                      </span>
                    ) : null}
                  </>
                )}
              </div>
              <span className="data-value">
                {formatCurrency(parseFloat(element.value), currency)}{" "}
                <span className="percentage-contribution">
                  {showTotal &&
                    `(${getPercentageContributionToTotal(
                      numberList,
                      parseFloat(element.value)
                    )}%)`}
                </span>
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default CustomToolTip;
