import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";

const routes = [
  {
    id: 1,
    path: "/dashboard",
    element: <Home />,
  },
  {
    id: 2,
    path: "/login",
    element: <Login />,
  },
];

export default routes
