import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer className="p-3 w-fit text-sm" />
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
