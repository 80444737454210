import axios from "axios";
import { responseErr, responseInterceptor } from "./responseInterceptors";
import { requestInteceptor } from "./requestInterceptors";

export const landingInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_URL}/api`
      : //@ts-ignore
        `${window.env.appUrl}/api`,
});

landingInstance.interceptors.request.use(
  //@ts-ignore
  (config: AxiosRequestConfig) => requestInteceptor(config),
  (error: any) => {
    return Promise.reject(error);
  }
);
landingInstance.interceptors.response.use(
  (res) => responseInterceptor(res),
  async (err) => responseErr(err, landingInstance, `$`)
);
