import { Tooltip } from "antd";
import { ReactComponent as InfoIcon } from "../../assets/icons/infoicon.svg";

import "./Heading.scss";
import { ReactNode } from "react";

interface HeadingType {
  isSectionHeading?: boolean;
  headingText: string;
  headingToolTipText: string;
  showHeader?: boolean;
  icon?: ReactNode;
  showToolTip?: boolean
}

const Heading = ({
  isSectionHeading = false,
  headingText,
  headingToolTipText,
  showHeader = true,
  icon,
  showToolTip=true,
}: HeadingType) => {
  return (
    <p
      className={`heading ${isSectionHeading ? "large" : "normal"} ${
        showHeader ? "show" : "hide"
      }`}>
      {icon && icon}
      {headingText}{" "}
      {showToolTip && <Tooltip title={headingToolTipText}>
        <span className="tooltip-icon">
          <InfoIcon />
        </span>
      </Tooltip>}
    </p>
  );
};

export default Heading;
