import LandingLayout from "../../components/LandingLayout/LandingLayout";
import CustomInput from "../../components/UIKits/CustomInput/CustomInput";
import PrimaryButton from "../../components/buttons/PrimaryButton/PrimaryButton";
import "./Login.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { login } from "../../services/auth";
import { useMutation } from "react-query";
import { saveLocalState } from "../../utils/localStorage";
import { AxiosError } from "axios";
import { Spinner } from "../../components/UIKits/Spinner/Spinner";


const schema = yup.object().shape({
  userName: yup.string().required("Email is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(4)
    .max(15),
});

const Login = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      userName: "",
      password: "",
    },
  });

  const { mutateAsync: authenticateUser, isLoading } = useMutation(login, {
    onSuccess: async ({ data }) => {
      await saveLocalState("NSIBUser", {
        ...data,
      });

      // window.location.href = "/home";
      if (data?.permission?.includes("Permission.Dashboard")) {
        navigate("/dashboard");
      } else {
        toast.error("You do not have permission to access the dashboard.");
      }
    },
    onError: (error: AxiosError) => {
      // @ts-ignore
      toast.error(error?.response?.data?.error);
    },
  });

  const onSubmit = (data: any) => {
    authenticateUser({
      ...data,
      rememberMe: false,
    });
  };

  
  const watchedUserName = watch("userName", "");
  const watchedPassword = watch("password", "");
  return (
    <div>
      <LandingLayout>
        <div className="w-[400px] flex-col gap-10 inline-flex m-auto px-2">
          <div className="login-text text-[32px] font-semibold font-['Archivo']">
            Log into your account
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-start items-start gap-[2px] mx-auto">
              <div className="w-full mb-3">
                <CustomInput
                  type="email"
                  name="userName"
                  value={watchedUserName}
                  label="Email"
                  placeholder=""
                  control={control}
                  errorMessage={errors.userName?.message}
                />
              </div>

              <div className="w-full mb-3">
                <CustomInput
                  type="password"
                  name="password"
                  value={watchedPassword}
                  label="Password"
                  placeholder=""
                  control={control}
                  errorMessage={errors.password?.message}
                />
              </div>
            </div>
            <div
              className="text-right text-[#5f38fa] text-sm font-semibold leading-tight cursor-pointer"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot password?
            </div>
            <div className="mt-8 text-center">
              <PrimaryButton
                label={
                  isLoading ? (
                    <Spinner/>
                  ) : (
                    "Log in"
                  )
                }
                type="submit"
                width="100%"
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
      </LandingLayout>
    </div>
  );
};

export default Login;
