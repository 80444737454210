import React, { useState, useEffect } from "react";
import { HamburgerMenu } from "solar-icon-set/essentionalui";
import { getFullNameInitials } from "../../utils/helper";
import { useQuery } from "react-query";
import { fetchUserProfile } from "../../services/settings";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { getSavedState } from "../../utils/localStorage";

interface ProfileSwitchType {
    onClick?: () => void;
}

const ProfileSwitch = ({ onClick }: ProfileSwitchType) => {
  const user = getSavedState("NSIBUser");
  const fullName = user?.fullName || "";

  return (
    <div className="w-[78px] h-10 pl-1 pr-2.5 py-1 bg-white rounded-3xl border border-[#ebebeb] flex justify-start items-center gap-2">
      {/* Profile initials circle */}
      <div className="w-8 h-8 relative bg-[#5f38fa] rounded-full flex items-center justify-center">
        <p className="text-white text-sm font-medium font-['Archivo']">
          {fullName ? getFullNameInitials(fullName) : "--"} 
        </p>
      </div>

      {/* Hamburger Menu */}
      <div className="w-5 h-5 relative flex items-center justify-center cursor-pointer" onClick={onClick}>
        <HamburgerMenu />
      </div>
    </div>
  );
};

export default ProfileSwitch;
