import { getPercentageContributionFromTotal } from "../../utils/helper";
import { PassengerFlightDataType } from "../PassengerFlightCard/types";

interface PassengerFlightChartType {
  chartData: PassengerFlightDataType;
  bigSize?: boolean;
}

const PassengerFlightChartAlt = ({ chartData }: PassengerFlightChartType) => {
  const maxPassengers = Math.max(...chartData.map((d) => d.passengers));
  const maxFlight = Math.max(...chartData.map((d) => d.flights));

  return (
    <div className="flex flex-col gap-2.5 w-full font-[Archivo] text-xs text-[#1A1B1D99]">
      {chartData?.map((data, key) => (
        <div key={key} className="flex items-center gap-1">
          <p className="w-[80px] line-clamp-3 font-medium">{data.name}</p>
          <div className="w-full">
            <div className="w-full flex items-center gap-4">
              <div
                className={`h-2 transition-all duration-300 bg-[#623BFA] rounded`}
                style={{
                  width: `${getPercentageContributionFromTotal(
                    maxPassengers,
                    data?.passengers
                  )+0.15}%`,
                }}></div>
              <span className="font-medium block w-[8%]">{data?.passengers}</span>
            </div>
            <div className="w-full flex items-center gap-4">
              <div
                className={`h-2 transition-all duration-300 bg-[#E1E1E1] rounded`}
                style={{
                  width: `${getPercentageContributionFromTotal(
                    maxFlight,
                    data?.flights
                  )+0.15}%`,
                }}></div>
                <span className="font-medium block w-[8%]" >{data?.flights}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PassengerFlightChartAlt;
