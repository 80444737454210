import { Modal } from "antd";
import { ReactNode } from "react";
import "./Modal.scss"

interface ModalContainerType {
    showTopCancelButton?: boolean;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    modalHeader?: string;
    children: ReactNode;
    footer?: ReactNode
    width?: string | number
  }

  const ModalContainer = ({
    showTopCancelButton,
    showModal,
    setShowModal,
    modalHeader,
    children,
    footer,
    width=900
  }: ModalContainerType) => {
    return (
      <Modal
      className="pt-10 !pb-10"
      width={width}
        title={modalHeader}
        centered
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}>
        <div className="content-container">{children}</div>
      </Modal>
    );
  };
  
  export default ModalContainer;