import { FC, useState } from "react";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";
import CustomInput from "../../UIKits/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Spinner } from "../../UIKits/Spinner/Spinner";
import { forgotPassword } from "../../../services/auth";
import ModalContainer from "../../Modal/Modal";
import { getSavedState } from "../../../utils/localStorage";

interface forgotPropType {
  showForgotModal: boolean;
  setShowForgotModal: (showForgotModal: boolean) => void;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Enter valid email")
    .required("Email is a required field"),
});

const ForgotPasswordModal = ({
  showForgotModal,
  setShowForgotModal,
}: forgotPropType) => {
  const [isSuccess, setIsSuccess] = useState(false); // Add state for success
  const user = getSavedState("NSIBUser");
  const savedEmail = user?.userName || "";
  const [userEmail, setUserEmail] = useState(savedEmail);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: savedEmail || "",
    },
  });

  const { mutateAsync: initiatePwdReset, isLoading } = useMutation(
    forgotPassword,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setUserEmail(watchedEmail); // Store the user's email
        setIsSuccess(true); // Set success state to true
        resetForm();
      },
      onError: (error: AxiosError) => {
        // @ts-ignore
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (data: any) => {
    initiatePwdReset(data);
  };

  const handleResend = () => {
    initiatePwdReset({ email: savedEmail }); // Resend the reset email
  };

  const handleTryDifferentEmail = () => {
    setIsSuccess(false); // Hide success message
    resetForm(); // Reset the form to re-enter a new email
  };
  const watchedEmail = watch("email", "");
  const openForgotModal = () => {
    setShowForgotModal(true);
  };

  return (
    <ModalContainer
      showModal={showForgotModal}
      setShowModal={setShowForgotModal}
      width={600}>
      <div className="w-full !mx-auto flex-col justify-start items-center gap-10 inline-flex py-10">
        {isSuccess ? (
          <div className="">
            <div className="text-stone-950 text-[32px] font-semibold ml-3 m-6">
              Check your mail
            </div>
            <div className="w-[400px] text-[#6A6A6A] text-base font-medium succesMessage">
              Thanks! If {savedEmail} matches an email we have on file, then
              we've sent you an email containing further instructions for
              resetting your password. 
              <br />
              <br />
              If you haven't received an email in 5
              minutes, check your spam, or {" "}
              <button onClick={handleResend} className="text-[#5f38fa]">
                resend
              </button>.
            </div>
          </div>
        ) : (
          <div>
            <div className="text-stone-950 text-[32px] font-semibold font-['Archivo']">
              Reset your password
            </div>
            <div className="w-[400px] text-[#6A6A6A] text-base font-medium mt-4">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col justify-start items-start gap-[2px] mx-auto mt-10">
                <div className="w-full mb-3">
                  <CustomInput
                    type="email"
                    name="email"
                    value={watchedEmail}
                    label="Email address"
                    placeholder="name@email.com"
                    isDisabled
                    control={control}
                    errorMessage={errors.email?.message?.toString()}
                  />
                </div>
              </div>

              <div className="mt-8 text-center">
                <PrimaryButton
                  label={isLoading ? <Spinner /> : "Continue"}
                  type="submit"
                  width="100%"
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};

export default ForgotPasswordModal;
