import { Spinner } from "../UIKits/Spinner/Spinner";

const DataLoadingIndicator = ({customStyling}: {customStyling?: string}) => {
  return (
    <div className={`w-full h-full ${customStyling ? customStyling : "aspect-auto"} flex items-center justify-center`}>
      <div className="-mt-10">
        <Spinner />
      </div>
    </div>
  );
};

export default DataLoadingIndicator;
