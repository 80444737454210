export const RevenueDataFilterData = [
  {
    type: "date",
    options: [
      {
        label: "Year to Date",
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      },
      {
        label: "Current Month",
        value: [
          new Date(new Date().setDate(1)),
          new Date(),
        ],
      },
      {
        label: "Last 12 Months",
        value: [
          new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
          new Date(),
        ],
      },
      {
        label: "Last Year",
        value: [
          new Date(new Date().getFullYear() - 1, 0, 1),
          new Date(new Date().getFullYear() - 1, 11, 31),
        ],
      },
      { label: "Custom Range", value: [] },
    ],
  },
];

export const TscPscData = {
  TSCDom: {
    name: "TSC domestic",
    values: {
      naira: {
        amount: 568240000,
        percentage_growth: 5,
        decrease: false,
      },

      dollar: {
        amount: 148343000,
        percentage_growth: 3,
        decrease: true,
      },
    },
    naira: [
      { name: "Jan", chosenPeriod: 5300000, previousPeriod: 2750000 },
      { name: "Feb", chosenPeriod: 6100000, previousPeriod: 3200000 },
      { name: "Mar", chosenPeriod: 5800000, previousPeriod: 3100000 },
      { name: "Apr", chosenPeriod: 5400000, previousPeriod: 2900000 },
      { name: "May", chosenPeriod: 6200000, previousPeriod: 3500000 },
      { name: "Jun", chosenPeriod: 5000000, previousPeriod: 2600000 },
      { name: "Jul", chosenPeriod: 5500000, previousPeriod: 3000000 },
      { name: "Aug", chosenPeriod: 5900000, previousPeriod: 3300000 },
      { name: "Sep", chosenPeriod: 5700000, previousPeriod: 3100000 },
      { name: "Oct", chosenPeriod: 6000000, previousPeriod: 3400000 },
      { name: "Nov", chosenPeriod: 6100000, previousPeriod: 3500000 },
      { name: "Dec", chosenPeriod: 6300000, previousPeriod: 3600000 },
    ],
    dollar: [
      { name: "Jan", chosenPeriod: 4000000, previousPeriod: 2200000 },
      { name: "Feb", chosenPeriod: 4500000, previousPeriod: 2500000 },
      { name: "Mar", chosenPeriod: 4200000, previousPeriod: 2400000 },
      { name: "Apr", chosenPeriod: 4600000, previousPeriod: 2600000 },
      { name: "May", chosenPeriod: 4700000, previousPeriod: 2800000 },
      { name: "Jun", chosenPeriod: 4300000, previousPeriod: 2300000 },
      { name: "Jul", chosenPeriod: 4500000, previousPeriod: 2500000 },
      { name: "Aug", chosenPeriod: 4600000, previousPeriod: 2700000 },
      { name: "Sep", chosenPeriod: 4400000, previousPeriod: 2600000 },
      { name: "Oct", chosenPeriod: 4700000, previousPeriod: 2800000 },
      { name: "Nov", chosenPeriod: 4600000, previousPeriod: 2700000 },
      { name: "Dec", chosenPeriod: 4800000, previousPeriod: 2900000 },
    ],
  },
  TSCIntl: {
    name: "TSC International",
    values: {
      naira: {
        amount: 886246000,
        percentage_growth: 5,
        decrease: true,
      },
      dollar: {
        amount: 359830000,
        percentage_growth: 10,
        decrease: false,
      },
    },

    naira: [
      { name: "Jan", chosenPeriod: 5400000, previousPeriod: 2800000 },
      { name: "Feb", chosenPeriod: 6000000, previousPeriod: 3200000 },
      { name: "Mar", chosenPeriod: 5700000, previousPeriod: 3000000 },
      { name: "Apr", chosenPeriod: 5600000, previousPeriod: 2900000 },
      { name: "May", chosenPeriod: 6300000, previousPeriod: 3400000 },
      { name: "Jun", chosenPeriod: 5100000, previousPeriod: 2700000 },
      { name: "Jul", chosenPeriod: 5500000, previousPeriod: 3100000 },
      { name: "Aug", chosenPeriod: 6000000, previousPeriod: 3400000 },
      { name: "Sep", chosenPeriod: 5900000, previousPeriod: 3200000 },
      { name: "Oct", chosenPeriod: 6200000, previousPeriod: 3500000 },
      { name: "Nov", chosenPeriod: 5800000, previousPeriod: 3300000 },
      { name: "Dec", chosenPeriod: 6400000, previousPeriod: 3600000 },
    ],

    dollar: [
      { name: "Jan", chosenPeriod: 4100000, previousPeriod: 2300000 },
      { name: "Feb", chosenPeriod: 4200000, previousPeriod: 2400000 },
      { name: "Mar", chosenPeriod: 4300000, previousPeriod: 2500000 },
      { name: "Apr", chosenPeriod: 4400000, previousPeriod: 2600000 },
      { name: "May", chosenPeriod: 4600000, previousPeriod: 2700000 },
      { name: "Jun", chosenPeriod: 4500000, previousPeriod: 2600000 },
      { name: "Jul", chosenPeriod: 4700000, previousPeriod: 2800000 },
      { name: "Aug", chosenPeriod: 4800000, previousPeriod: 2900000 },
      { name: "Sep", chosenPeriod: 4900000, previousPeriod: 3000000 },
      { name: "Oct", chosenPeriod: 5000000, previousPeriod: 3100000 },
      { name: "Nov", chosenPeriod: 4700000, previousPeriod: 2900000 },
      { name: "Dec", chosenPeriod: 5100000, previousPeriod: 3200000 },
    ],
  },
  PSCDom: {
    name: "PSC Domestic",
    values: {
      naira: {
        amount: 134540000,
        percentage_growth: 5,
        decrease: false,
      },
      dollar: null,
    },
    dollar: null,
    naira: [
      { name: "Jan", chosenPeriod: 5500000, previousPeriod: 2900000 },
      { name: "Feb", chosenPeriod: 6200000, previousPeriod: 3300000 },
      { name: "Mar", chosenPeriod: 6000000, previousPeriod: 3200000 },
      { name: "Apr", chosenPeriod: 5700000, previousPeriod: 3100000 },
      { name: "May", chosenPeriod: 6400000, previousPeriod: 3500000 },
      { name: "Jun", chosenPeriod: 5100000, previousPeriod: 2800000 },
      { name: "Jul", chosenPeriod: 5600000, previousPeriod: 3000000 },
      { name: "Aug", chosenPeriod: 5900000, previousPeriod: 3300000 },
      { name: "Sep", chosenPeriod: 6200000, previousPeriod: 3400000 },
      { name: "Oct", chosenPeriod: 6100000, previousPeriod: 3200000 },
      { name: "Nov", chosenPeriod: 6300000, previousPeriod: 3500000 },
      { name: "Dec", chosenPeriod: 6600000, previousPeriod: 3600000 },
    ],
  },
  PSCReg: {
    name: "PSC Regional",
    values: {
      naira: null,
      dollar: {
        amount: 568240000,
        percentage_growth: 5,
        decrease: false,
      },
    },
    naira: null,
    dollar: [
      { name: "Jan", chosenPeriod: 4200000, previousPeriod: 2400000 },
      { name: "Feb", chosenPeriod: 4300000, previousPeriod: 2500000 },
      { name: "Mar", chosenPeriod: 4400000, previousPeriod: 2600000 },
      { name: "Apr", chosenPeriod: 4500000, previousPeriod: 2700000 },
      { name: "May", chosenPeriod: 4600000, previousPeriod: 2800000 },
      { name: "Jun", chosenPeriod: 4700000, previousPeriod: 2900000 },
      { name: "Jul", chosenPeriod: 4800000, previousPeriod: 3000000 },
      { name: "Aug", chosenPeriod: 4900000, previousPeriod: 3100000 },
      { name: "Sep", chosenPeriod: 5000000, previousPeriod: 3200000 },
      { name: "Oct", chosenPeriod: 5100000, previousPeriod: 3300000 },
      { name: "Nov", chosenPeriod: 5200000, previousPeriod: 3400000 },
      { name: "Dec", chosenPeriod: 5300000, previousPeriod: 3500000 },
    ],
  },
};

export const TopFourFlightData = [
  {
    name: "Air Peace",
    value: 150000,
  },
  {
    name: "Ibom air",
    value: 100000,
  },
  {
    name: "Arik",
    value: 50000,
  },
  {
    name: "Aero contractors",
    value: 50000,
  },
];

export const RevenueBreakdownData = {
  data: [
    {
      Currency: "Naira",
      Values: [
        {
          name: "ABV",
          tscDom: 300000000,
          tscInt: 450000000,
          pscDom: 560000000,
          pscReg: 0,
        },
        {
          name: "LOS",
          tscDom: 100000000,
          tscInt: 550000000,
          pscDom: 50000000,
          pscReg: 0,
        },
        {
          name: "PHC",
          tscDom: 30000000,
          tscInt: 40000000,
          pscDom: 60000000,
          pscReg: 0,
        },
        {
          name: "KAN",
          tscDom: 100000000,
          tscInt: 5000000,
          pscDom: 30006000,
          pscReg: 0,
        },
        {
          name: "KAN",
          tscDom: 100000000,
          tscInt: 20000000,
          pscDom: 50000000,
          pscReg: 0,
        },
      ],
    },
    {
      Currency: "Dollar",
      Values: [
        {
          name: "ABV",
          tscDom: 400000,
          tscInt: 250000,
          pscDom: 0,
          pscReg: 200000,
        },
        {
          name: "LOS",
          tscDom: 360000,
          tscInt: 650000,
          pscDom: 0,
          pscReg: 30000,
        },
        {
          name: "PHC",
          tscDom: 860000,
          tscInt: 10000,
          pscDom: 0,
          pscReg: 200000,
        },
        {
          name: "KAN",
          tscDom: 600000,
          tscInt: 650000,
          pscDom: 0,
          pscReg: 70000,
        },
        {
          name: "KAN",
          tscDom: 300000,
          tscInt: 290000,
          pscDom: 0,
          pscReg: 250000,
        },
      ],
    },
  ],
};

export const PassengerFlightData = [
  {
    name: "Air Peace",
    passengers: 4000,
    flights: 2400,
  },
  {
    name: "Azman Air",
    passengers: 3000,
    flights: 1398,
  },
  {
    name: "Ibom Air",
    passengers: 2000,
    flights: 5800,
  },
  {
    name: "Aero",
    passengers: 2780,
    flights: 3908,
  },
  {
    name: "Dana Air",
    passengers: 1890,
    flights: 4800,
  },
  {
    name: "Air Peace",
    passengers: 4000,
    flights: 2400,
  },
  {
    name: "Azman Air",
    passengers: 3000,
    flights: 1398,
  },
  {
    name: "Ibom Air",
    passengers: 2000,
    flights: 4800,
  },
  {
    name: "Aero",
    passengers: 2780,
    flights: 3908,
  },
  {
    name: "Dana Air",
    passengers: 1890,
    flights: 4800,
  },
  {
    name: "Air Peace",
    passengers: 4000,
    flights: 2400,
  },
  {
    name: "Azman Air",
    passengers: 3000,
    flights: 1398,
  },
  {
    name: "Ibom Air",
    passengers: 2000,
    flights: 4800,
  },
  {
    name: "Aero",
    passengers: 2780,
    flights: 3908,
  },
  {
    name: "Dana Air",
    passengers: 1890,
    flights: 4800,
  },
];

export const TopRoutesData = {
  totalFlights: 181,
  data: [
    {
      name: "ABV - LOS",
      value: 52,
      percentage: "50%",
    },
    {
      name: "LOS - ABV",
      value: 31,
      percentage: "30%",
    },
    {
      name: "PHC - LOS",
      value: 48,
      percentage: "50%",
    },
    {
      name: "ABV - PHC",
      value: 20,
      percentage: "30%",
    },
    {
      name: "LOS - PHC",
      value: 30,
      percentage: "50%",
    },
  ],
};

export const InsightData = [
  {
    gmv_report: {
      title: "14%",
      increase: true,
      bodyOne:
        "Increase in GMV across both naira and dollar revenue lines compared to April.",
      bodyTwo:
        "Dollar revenue rose by 10% to $230,000, while Naira revenue increased by 20% to N350 million. This growth reflects a rise in flight operations and passenger activity over the past month. ",
    },
    flight_leader: {
      title: "Air peace",
      TextToBolden: "N200 million",
      bodyOne:
        "Air Peace leds in both revenue generation and route coverage during the period, recording a total of N200 million. ",
      bodyTwo:
        "This positions the airline as the top performer in flight operations.  ",
    },
    top_route: {
      title: "LOS-ABV",
      values: [
        {
          name: "Revenue",
          amount: 23000000,
          percentage: 5,
          decrease: false,
        },
        {
          name: "Passenger count",
          amount: 20000,
          percentage: 5,
          decrease: false,
        },
        {
          name: "Flight count",
          amount: 74,
          percentage: 5,
          decrease: false,
        },
      ],
    },
    passengerTrend: {
      title: "14%",
      increase: true,
      bodyOne: "Passenger trend over the last 7days",
    },
    flightOperations: {
      title: "8%",
      increase: false,
      bodyOne: "Decline in flight operations compared to the previous month",
      bodyTwo:
        "In July, NCAA TSC Domestic recorded 5,000 flights, reflecting a 2.1% decline in flight operations compared to the 5,100 flights in April. This drop indicates a slight decrease in Flight operational activity over the period. ",
    },
  },
];

export const AirportRevenueTableData = [
  {
    Name: "Murtala Muhammed International Airport",
    Code: "LOS",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 443.3,
    TscDomesticNaira: 9893486.11,
    TscInternationalNaira: 938140.0,
    PscDomestic: 960.0,
    PscRegional: 960.0,
  },
  {
    Name: "Benin Airport",
    Code: "BNI",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 1518278.89,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Asaba International Airport",
    Code: "ABB",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 1817045.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Akure Airport",
    Code: "AKR",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 380900.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Nnamdi Azikwe International Airport",
    Code: "ABV",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 11787285.0,
    TscInternationalNaira: 647000.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Port Harcourt Airport",
    Code: "PHC",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 2708018.89,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Douala International Airport",
    Code: "DLA",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 0.0,
    TscInternationalNaira: 103750.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Sam Mbakwe International Cargo Airport",
    Code: "QOW",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 1775950.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Aminu Kano International Airport",
    Code: "KAN",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 1734570.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "ANA",
    Code: "ANA",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 0.0,
    TscInternationalNaira: 585600.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Kotoka International Airport",
    Code: "ACC",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 0.0,
    TscInternationalNaira: 290330.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "AKANA IBIAM INTERNATIONA;L AIRPORT",
    Code: "ENU",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 870738.61,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Ilorin International Airport",
    Code: "ILR",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 360660.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Warri Airport",
    Code: "QRW",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 1040000.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Ibadan Airport",
    Code: "IBA",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 271060.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Gombe Lawanti International Airport",
    Code: "GMO",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 445440.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Lungi International Airport",
    Code: "FNA",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 0.0,
    TscInternationalNaira: 115740.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
  {
    Name: "Margaret Ekpo International Airport",
    Code: "CBQ",
    TscDomesticDollar: 0.0,
    TscInternationalDollar: 0.0,
    TscDomesticNaira: 326340.0,
    TscInternationalNaira: 0.0,
    PscDomestic: 0.0,
    PscRegional: 0.0,
  },
];
