import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from "recharts";
import { PieChartDataListType, PieChartDataType } from "../../TopRoutesCard/types";

import "./TopRoutePieChart.scss";
import { useState } from "react";

interface TopRoutePieChartType {
  chartData: PieChartDataListType[];
  sumTotal: number;
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
}

const renderActiveShape = (props: any) => {
  const hexToRgba = (hex: string, opacity: number) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r},${g},${b},${opacity})`;
  };

  const transparentFill = hexToRgba(props?.fill, 0.3); // Adjust the transparency as needed

  return (
    <g>
      <Sector
        cx={props?.cx}
        cy={props?.cy}
        innerRadius={props?.innerRadius}
        outerRadius={props?.outerRadius}
        startAngle={props?.startAngle}
        endAngle={props?.endAngle}
        fill={props?.fill}
      />
      <Sector
        cx={props?.cx}
        cy={props?.cy}
        startAngle={props?.startAngle}
        endAngle={props?.endAngle}
        innerRadius={props?.outerRadius + 6}
        outerRadius={props?.outerRadius + 10}
        fill={transparentFill}
      />
    </g>
  );
};

const TopRoutePieChart = ({
  chartData,
  activeIndex,
  setActiveIndex,
  sumTotal,
}: TopRoutePieChartType) => {
  const [middleText, setMiddleText] = useState({
    name: "Total Flights",
    value: sumTotal,
  });

  const onPieEnter = (_: any, index: number) => {
    setMiddleText({
      name: chartData[index]?.name,
      value: chartData[index]?.value,
    });
    setActiveIndex(index);
  };

  const resetValues = () => {
    setMiddleText({ name: "Total Flights", value: sumTotal });
    setActiveIndex(-1);
  };

  const chartColors = ["#623BFA", "#38FA7B", "#FA7B38", "#FACD38", "#DEE0E3"];
  return (
    <div className="top-route-pie-chart w-[50%] aspect-square flex justify-center items-center">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart className="chart">
          <Pie
            width="100%"
            height="100%"
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius={"65%"}
            radius={10}
            style={{ stroke: "#fff", strokeWidth: 2 }}
            fill="#8884d8"
            dataKey="value"
            paddingAngle={2}
            onMouseEnter={onPieEnter}
            onMouseOut={resetValues}>
            <Label
              value={middleText?.name}
              dy={-15}
              position="center"
              className="name"
              fill="#0D0D0D80"
            />
            <Label
              dy={10}
              value={middleText?.value}
              position="center"
              className="value"
              fill="#0D0D0D"
            />
            {chartData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={chartColors[index]}
                radius={10}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TopRoutePieChart;
