import { useQuery } from "react-query";
import { DateFilterType } from "../../pages/Home/types";
import { formatCurrency } from "../../utils/helper";
import TopGenAirlineChart from "../charts/TopGenAirlineChart/TopGenAirlineChart";
import Heading from "../Heading/Heading";
import { getTopFourAirlines } from "../../services/home";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import { singleTopRouteDataType } from "./types";

import "./TopGeneratingAirlinesCard.scss";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";
import { useState } from "react";
import { toast } from "react-toastify";

const TopGeneratingAirlinesCard = ({
  dateFilter,
}: {
  dateFilter: DateFilterType;
}) => {
  const dataPointColors = ["#5F38FA", "#FAE238", "#FA8338", "#38FA7B"];

  const {
    isLoading,
    data: topFourAirlines,
    isError,
  } = useQuery(
    [
      "topFourAirlines",
      {
        dateFilter,
      },
    ],
    () =>
      getTopFourAirlines({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );

  const sumTotal =
    topFourAirlines?.data.reduce(
      (sum: number, route: singleTopRouteDataType) => sum + route.amount,
      0
    ) || 0;

  return (
    <div className="top-gen-airline-card">
      <div className="top-text-container">
        <div className="">
          <Heading
            isSectionHeading={true}
            headingText={"Top 4 generating airlines"}
            headingToolTipText={
              "This data is sourced from NCAA TSC Domestic Database"
            }
          />
          <p className="amount">{formatCurrency(sumTotal)}</p>
        </div>
        <div className="">
          {isLoading
            ? null
            : isError
            ? null
            : topFourAirlines?.data?.map(
                (flight: singleTopRouteDataType, index: number) => (
                  <p key={index} className="airlines capitalize">
                    <span
                      className="indicator"
                      style={{
                        backgroundColor: dataPointColors[index],
                      }}></span>
                    {flight?.name}
                  </p>
                )
              )}
        </div>
      </div>
      {isLoading ? (
        <DataLoadingIndicator customStyling="aspect-[1/1]" />
      ) : isError ? (
        <NoDataIndicator
          customText="An error occurred, please try again later!"
          customStyling="aspect-[1/1]"
        />
      ) : topFourAirlines?.data.length === 0 ? (
        <NoDataIndicator customStyling="aspect-[1/1]" />
      ) : (
        <TopGenAirlineChart
          chartData={topFourAirlines?.data}
          barColors={dataPointColors}
          dataPointColors={dataPointColors}
        />
      )}
    </div>
  );
};

export default TopGeneratingAirlinesCard;
