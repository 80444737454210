import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "./PscTscChart.scss";
import CustomToolTip from "../common/CustomToolTip/CustomToolTip";
import CustomLegend from "../common/CustomLegend/CustomLegend";
import { TscPscChartDataType } from "../../TscPscCard/types";
import moment from "moment";

interface PscTscChartType {
  chartData: TscPscChartDataType[] | null;
  currency: string;
}

const PscTscChart = ({ chartData, currency }: PscTscChartType) => {
  return (
    <div className="psc-tsc-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData ? chartData : []}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#ffffff" color="#000" />
          <XAxis
            stroke="#fff"
            dataKey="name"
            tick={{ fill: "#000000B2" }}
            tickFormatter={(name) =>
              moment(name, "YYYY-M").format("MMM").toUpperCase()
            }
          />
          <YAxis stroke="#fff" tick={{ fill: "#000000B2" }} />
          <Legend
            layout="horizontal"
            align="right"
            verticalAlign={"top"}
            content={<CustomLegend payload={[]} />}
            wrapperStyle={{ paddingBottom: "30px" }}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={
              <CustomToolTip
                active={false}
                payload={[]}
                label=""
                currency={currency}
                showDataName={false}
              />
            }
          />
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                style={{
                  stopColor: "rgba(95, 56, 250, 0.25)",
                  stopOpacity: 0.9,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: "rgba(95, 56, 250, 0.22)",
                  stopOpacity: 0.2,
                }}
              />
            </linearGradient>
            <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                style={{
                  stopColor: "rgba(225, 225, 225, 1)",
                  stopOpacity: 0.3,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: "rgba(225, 225, 225, 1)",
                  stopOpacity: 0.15,
                }}
              />
            </linearGradient>
            <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                style={{
                  stopColor: "rgba(95, 56, 250, 1)",
                  stopOpacity: 0.9,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: "rgba(95, 56, 250, 0.22)",
                  stopOpacity: 0.2,
                }}
              />
            </linearGradient>
            <linearGradient id="gradient4" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                style={{
                  stopColor: "rgba(225, 225, 225, 1)",
                  stopOpacity: 0.9,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: "rgba(225, 225, 225, 1)",
                  stopOpacity: 0.15,
                }}
              />
            </linearGradient>
          </defs>
          <Bar
            dataKey="chosenPeriod"
            fill={"url(#gradient1)"}
            radius={10}
            activeBar={<Rectangle fill="url(#gradient3)" />}
          />
          <Bar
            dataKey="previousPeriod"
            fill={"url(#gradient2)"}
            radius={10}
            activeBar={<Rectangle fill="url(#gradient4)" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PscTscChart;
