import { FC, useState } from "react";
import { Settings } from "solar-icon-set/settingsfinetuning";
import { ReactComponent as LogOutIcon } from "../../assets/icons/logouticon.svg";
import { deleteLocalState, getSavedState } from "../../utils/localStorage";
import { getFullNameInitials } from "../../utils/helper";
import SettingsContainer from "../Settings/Settings";
import "./LogoutCard.scss"

const LogoutPopover: FC = () => {
  const user = getSavedState("NSIBUser");
  const fullName = user?.fullName || "";
  const userEmail = user?.userName || "";
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    deleteLocalState("NSIBUser");
    window.location.href = "/login";
  };

  const openSettingsModal = () => {
   setShowModal(true);
  };

  return (
    <div className="w-[240px] bg-white rounded-lg shadow-lg cursor-pointer">
      <div className="flex items-center p-4">
        <div className="w-10 h-10 bg-[#F7F5FF] text-[#5f38fa] font-medium text-lg rounded-full flex justify-center items-center">
        {getFullNameInitials(fullName)}
        </div>
        <div className="ml-3">
          <div className="text-gray-900 font-medium line-clamp-1">{fullName}</div>
          <div className="text-gray-500 text-[10px]">{userEmail}</div>
        </div>
      </div>


      <button className="flex items-center gap-2 text-gray-800  logout-button !pl-6" onClick={openSettingsModal}>
        <Settings size={20} className="text-gray-700" />
        <span className="font-medium">Settings</span>
      </button>
      <div className="border-t border-gray-200" />

      <button className="flex items-center gap-2 cursor-pointer logout-button !pl-6" onClick={handleLogout}>
        <LogOutIcon size={20} className="" />
        <span className="font-medium">Log out</span>
      </button>

      {showModal && 
      <SettingsContainer showModal={showModal} setShowModal={setShowModal} />}
    </div>
  );
};

export default LogoutPopover;
