import ModalContainer from "../Modal/Modal";
import { ReactComponent as CalenderIcon } from "../../assets/icons/calender.svg";

import "./PassengerFlightPopup.scss";
import OutlineButton from "../buttons/OutlineButton/OutlineButton";
import StatsTextCard from "../StatsTextCard/StatsTextCard";
import { useState } from "react";
import moment from "moment";
import { Popover } from "antd";
import DateRangeTwo from "../DateRangeTwo/DateRangeTwo";
import { FilterSelectionType } from "../../pages/Home/types";
import { useQuery } from "react-query";
import { getPassengerFlightCountDetail } from "../../services/home";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";
import PassengerFlightChartAlt from "../PassengerFlightChartAlt/PassengerFlightChartAlt";
import { toast } from "react-toastify";

interface PassengerFlightPopupType {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const PassengerFlightPopup = ({
  showModal,
  setShowModal,
}: PassengerFlightPopupType) => {
  const [openDatePopOver, setOpenDatePopOver] = useState(false);

  const [comparisonPeriod, setComparisonPeriod] = useState("Last 7 days");
  const [flightDataDateFilters, setFlightDataDateFilters] = useState({
    StartDate: moment().subtract(6, "days").format("yy-MM-DD"),
    EndDate: moment().format("yy-MM-DD"),
  });

  const applyFilter = (params: FilterSelectionType) => {
    const dateValue = params?.value as Date[];
    const StartDate = dateValue
      ? moment(dateValue[0]).format("yy-MM-DD")
      : moment().subtract(6, "days").format("yy-MM-DD");
    const EndDate = dateValue
      ? moment(dateValue[1]).format("yy-MM-DD")
      : moment().format("yy-MM-DD");

    const newFilters = {
      StartDate,
      EndDate,
    };

    setComparisonPeriod(params?.label);
    setFlightDataDateFilters(newFilters);
  };

  const {
    isLoading,
    data: passengerFlightDetailData,
    isError,
  } = useQuery(
    [
      "passengerflightcountDetail",
      {
        flightDataDateFilters,
      },
    ],
    () =>
      getPassengerFlightCountDetail({
        StartDate: flightDataDateFilters?.StartDate,
        EndDate: flightDataDateFilters?.EndDate,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );

  return (
    <ModalContainer
      showModal={showModal}
      setShowModal={setShowModal}
      width={"80%"}>
      <div className="passenger-flight-popup">
        <h3>Passenger and flight count</h3>
        <Popover
          content={
            <DateRangeTwo
              applyRangeValue={applyFilter}
              setOpenDatePopOver={setOpenDatePopOver}
            />
          }
          trigger="click"
          open={openDatePopOver}
          onOpenChange={setOpenDatePopOver}
          className="popover-container"
          placement="bottomLeft"
          overlayStyle={{ width: "fit-content" }}>
          <div>
            <OutlineButton
              label={comparisonPeriod}
              icon={<CalenderIcon />}
              className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
            />
          </div>
        </Popover>
        {isLoading ? (
          <DataLoadingIndicator customStyling="aspect-[3/1] mt-6" />
        ) : isError ? (
          <NoDataIndicator
            customText="An error occurred, please try again later!"
            customStyling="aspect-[3/1] mt-6"
          />
        ) : (
          <div className="info-container">
            <div className="text-part">
              <div className="manual-legend">
                <div className="marker-container">
                  <span className={`marker0 marker flatten`}></span>
                  <p>Passengers</p>
                </div>
                <div className="marker-container">
                  <span className={`marker1 marker flatten`}></span>
                  <p>Flights</p>
                </div>
              </div>
              <StatsTextCard
                amount={
                  passengerFlightDetailData?.data?.data?.passengersInfo?.amount
                }
                percentageGrowth={passengerFlightDetailData?.data?.data?.passengersInfo?.percentageGrowth?.slice(
                  0,
                  -1
                )}
                isDecrease={
                  passengerFlightDetailData?.data?.data?.passengersInfo
                    ?.decrease
                }
                headingText={"Total passengers"}
                headingToolTipText={
                  "This data is sourced from the NCAA TSC Domestic Database"
                }
                isMoney={false}
              />
              <hr />
              <StatsTextCard
                amount={
                  passengerFlightDetailData?.data?.data?.flightsInfo?.amount
                }
                percentageGrowth={passengerFlightDetailData?.data?.data?.flightsInfo?.percentageGrowth?.slice(
                  0,
                  -1
                )}
                isDecrease={
                  passengerFlightDetailData?.data?.data?.flightsInfo?.decrease
                }
                headingText={"Total flights"}
                headingToolTipText={
                  "This data is sourced from the NCAA TSC Domestic Database"
                }
                isMoney={false}
              />
            </div>
            <div className="graph-holder">
              {passengerFlightDetailData?.data?.airlines?.length === 0 ? (
                <NoDataIndicator customStyling="aspect-[3/1]" />
              ) : (
                <PassengerFlightChartAlt
                  chartData={passengerFlightDetailData?.data?.airlines}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};

export default PassengerFlightPopup;
