import { devInstance } from "../utils/axios/axiosInstance";

export const uploadProfilePicture = async (file: any) => {
    const payload = {
      ProfilePicture: file,
    };
    const response = await devInstance.post(
      "/UserManagement/profilePicture",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };

  export const selfUpdate = async (payload: any) => {
    const response = await devInstance.post('/UserManagement/selfUpdate', payload)
    return  response.data
}

export const fetchUserProfile = async () => {
  try {
    const response = await devInstance.get("/UserManagement/profile");
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};