import * as yup from "yup";

export const userProfileValidationSchema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .test("minLength", "Phone number must be 11 digits", function (value) {
      if (value) {
        // If the value exists, check the minimum length
        return value.length == 11;
      }
      // If the value is undefined or empty, no validation is needed
      return true;
    }),
});

export const userPasswordManagement = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Current password is required.")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .test(
      "minLength",
      "Password must be at least 8 characters long",
      function (value) {
        if (value) {
          // If the value exists, check the minimum length
          return value.length >= 8;
        }
        // If the value is undefined or empty, no validation is needed
        return true;
      }
    ),
  newPassword: yup
    .string()
    .required("New password is required.")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .test(
      "minLength",
      "Password must be at least 8 characters long",
      function (value) {
        if (value) {
          // If the value exists, check the minimum length
          return value.length >= 8;
        }
        // If the value is undefined or empty, no validation is needed
        return true;
      }
    ),
  confirmPassword: yup
    .string()
    .required("Retype new password is required.")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .oneOf(
      [yup.ref("newPassword")],
      "Retype new passwords must match new password"
    )
    .test(
      "minLength",
      "Password must be at least 8 characters long",
      function (value) {
        if (value) {
          // If the value exists, check the minimum length
          return value.length >= 8;
        }
        // If the value is undefined or empty, no validation is needed
        return true;
      }
    ),
});
