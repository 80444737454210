import React, { useState, useEffect } from "react";
import LandingLayout from "../../components/LandingLayout/LandingLayout";
import PrimaryButton from "../../components/buttons/PrimaryButton/PrimaryButton";
import CustomInput from "../../components/UIKits/CustomInput/CustomInput";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { resetPassword } from "../../services/auth";
import { saveLocalState } from "../../utils/localStorage";
import { toast } from "react-toastify";
import { Spinner } from "../../components/UIKits/Spinner/Spinner";
import "./Resetpassword.scss";
import NSIBlogo from "../../assets/img/nsib-logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/checkmark.svg";
import { ReactComponent as GradientBackground } from "../../assets/icons/gradientbackground.svg";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .max(40, "Password must not exceed 40 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword"), "null"], "Confirm Password does not match"),
  userName: yup
    .string()
    .email("Enter valid email")
    .required("Username is a required field"),
  code: yup
    .string()
    .required("Please enter the token that was sent to your email"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");
  const [isSuccess, setIsSuccess] = useState(false); // Add state for success
  const passwordResetToken = searchParams.get("code");
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { mutateAsync: authenticateUser, isLoading } = useMutation(
    resetPassword,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setIsSuccess(true); // Set success state to true
        // navigate("/login");
      },
      onError: (error: AxiosError) => {
        // @ts-ignore
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (data: any) => {
    authenticateUser({
      ...data,
    });
  };

  useEffect(() => {
    if (passwordResetToken) {
      setValue("code", passwordResetToken, {
        shouldValidate: true,
      });
    }
    if (userEmail) {
      setValue("userName", userEmail, {
        shouldValidate: true,
      });
    }
  }, [passwordResetToken, userEmail]);

  const watchedNewpassword = watch("newPassword", "");
  const watchedConfirmpassword = watch("confirmPassword", "");
  return (
    <LandingLayout 
    disableScroll={false}
    >
      <div className=" w-full flex items-center justify-center">
        <div className="w-[400px] !h-fit flex-col gap-8 inline-flex login-container m-auto items-center justify-center py-10">
          {isSuccess ? (
            <div className="flex flex-col justify-center gap-6 -mt-10">
              <div className="check-icons">
                <GradientBackground className="rotating-background" />
                <CheckMarkIcon className="check-svgs" />
              </div>

              <div className="text-[32px] font-semibold text-center">
                You’ve successfully changed your password
              </div>
              <>
                <div className="mt-8 text-center">
                  <PrimaryButton
                    label={"Continue to login"}
                    onClick={() => (window.location.href = "/login")}
                    width="100%"
                  />
                </div>
              </>
            </div>
          ) : (
            <div className=" space-y-4 -mt-10">
              <img
                src={NSIBlogo}
                alt="NSIB Logo"
                className="nsib-logo"
              />

              <div className="login-text text-[32px] font-semibold font-['Archivo']">
                Reset your password
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col justify-start items-start gap-[2px] mx-auto">
                  <div className="w-[350px] mb-3">
                    <CustomInput
                      type="password"
                      name="newPassword"
                      value={watchedNewpassword}
                      label="New Password"
                      placeholder=""
                      control={control}
                      errorMessage={errors.newPassword?.message}
                    />
                  </div>

                  <div className="w-[350px] mb-3">
                    <CustomInput
                      type="password"
                      name="confirmPassword"
                      value={watchedConfirmpassword}
                      label="Confirm your password"
                      placeholder=""
                      control={control}
                      errorMessage={errors.confirmPassword?.message}
                    />
                  </div>
                </div>

                <div className="mt-8 text-center">
                  <PrimaryButton
                    label={isLoading ? <Spinner /> : "Continue"}
                    // label="log"

                    type="submit"
                    width="100%"
                  />
                </div>
              </form>
              <span className="md:hidden text-base font-normal footer-text">
                Simplifying your payouts...
              </span>
            </div>
          )}
        </div>
      </div>
    </LandingLayout>
  );
};

export default ResetPassword;

