import { landingInstance } from "../utils/axios/landingInstance";

export const login = async (payload: any) => {
  const response = await landingInstance.post("/Account/Login", payload);
  return response;  
};

export const forgotPassword = async (payload: any) => {
  const response = await landingInstance.get(
    `/Account/ForgotPassword?email=${payload.email}&source=1`
  );
  return response;
};

export const resetPassword = async (payload: any) => {
    const response = await landingInstance.post(
      "/Account/ResetPassword",
      payload
    );
    return response;
  };

  export const changePassword = async (payload: any) => {
    const response = await landingInstance.post("/Account/ChangePassword", payload);
    return response;  
  };