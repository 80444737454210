import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { TopGenAirlineType } from "../../TopGeneratingAirlinesCard/types";

import "./TopGenAirlineChart.scss";
import { formatCurrency } from "../../../utils/helper";
import CustomToolTip from "../common/CustomToolTip/CustomToolTip";

interface TopGenAirlineChartType {
  chartData: TopGenAirlineType;
  barColors: string[];
  dataPointColors: string[];
}

const TopGenAirlineChart = ({
  chartData,
  barColors,
  dataPointColors
}: TopGenAirlineChartType) => {
  const nameList = chartData.map(item =>(
    item?.name
  ))
  return (
    <div className="top-gen-airline-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData}>
        <Tooltip
            cursor={{ fill: "transparent" }}
            content={
              <CustomToolTip
                active={false}
                payload={[]}
                label=""
                currency={"naira"}
                showDataName={false}
                customNames={nameList}
                dataPointColors={dataPointColors}
              />
            }
            // key={"name"}
          />
          <XAxis
            dataKey="amount"
            stroke="#fff"
            tick={{ fill: "#000000B2" }}
            tickFormatter={(amount) => formatCurrency(amount)}
          />
          <defs>
            {barColors.map((barColor, index) => (
              <linearGradient
                key={index}
                id={`gradient1${index + 1}`}
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%">
                <stop
                  offset="0%"
                  style={{
                    stopColor: barColor,
                    stopOpacity: 0.9,
                  }}
                />
                <stop
                  offset="100%"
                  style={{
                    stopColor: barColor,
                    stopOpacity: 0,
                  }}
                />
              </linearGradient>
            ))}
          </defs>
          <Bar dataKey="amount" radius={10} barSize={65}>
            {chartData && chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`url(#gradient1${index + 1})`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TopGenAirlineChart;
