import Heading from "../Heading/Heading";
import "./PassengerFlightCard.scss";
import OutlineButton from "../buttons/OutlineButton/OutlineButton";
import { useState } from "react";
import PassengerFlightPopup from "../PassengerFlightPopup/PassengerFlightPopup";
import { DateFilterType } from "../../pages/Home/types";
import { useQuery } from "react-query";
import { getPassengerFlightCount } from "../../services/home";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";
import PassengerFlightChartAlt from "../PassengerFlightChartAlt/PassengerFlightChartAlt";
import { toast } from "react-toastify";

const PassengerFlightCard = ({
  dateFilter,
}: {
  dateFilter: DateFilterType;
}) => {
  const [showModal, setShowModal] = useState(false);

  const {
    isLoading,
    data: passengerFlightData,
    isError,
  } = useQuery(
    [
      "passengerflightcount",
      {
        dateFilter,
      },
    ],
    () =>
      getPassengerFlightCount({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );

  return (
    <div className="passenger-flight-card col-span-auto w-full row-auto h-full overflow-hidden">
      <div className="top-container">
        <div className="manual-legend">
          <Heading
            isSectionHeading={true}
            headingText={"Passenger and flight count"}
            headingToolTipText={
              "This data is sourced from the NCAA TSC Domestic Database"
            }
          />
          <div className="marker-container">
            <span className={`marker0 marker flatten`}></span>
            <p>Passengers</p>
          </div>
          <div className="marker-container">
            <span className={`marker1 marker flatten`}></span>
            <p>Flights</p>
          </div>
        </div>
        <OutlineButton
          label={"View more"}
          className="!px-3 !py-1.5"
          onClick={() => setShowModal(true)}
        />
      </div>
      {isLoading ? (
        <DataLoadingIndicator customStyling={"aspect-[3/1]"} />
      ) : isError ? (
        <NoDataIndicator
          customText="An error occurred, please try again later!"
          customStyling="aspect-[3/1]"
        />
      ) : passengerFlightData?.data?.airlines?.length === 0 ? (
        <NoDataIndicator customStyling={"aspect-[3/1]"} />
      ) : (
        <div className="flex h-[85%] w-full items-center pb-2">
          <PassengerFlightChartAlt
            chartData={passengerFlightData?.data?.airlines?.slice(0, 5)}
          />
        </div>
      )}
      <PassengerFlightPopup showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default PassengerFlightCard;
