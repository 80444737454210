import OutlineButton from "../buttons/OutlineButton/OutlineButton";
import ModalContainer from "../Modal/Modal";
import { ReactComponent as CalenderIcon } from "../../assets/icons/calender.svg";
import { RevenueDataFilterData } from "../../data/samples";
import Heading from "../Heading/Heading";
import CurrencyRadioSelector from "../CurrencyRadioSelector/CurrencyRadioSelector";
import { useEffect, useState } from "react";
import StatsTextCard from "../StatsTextCard/StatsTextCard";
import RevenueBreakDownChart from "../charts/RevenueBreakDownChart/RevenueBreakDownChart";
import CustomInput from "../UIKits/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import { ReactComponent as Magnifer } from "../../assets/icons/search.svg";

import { formatCurrency } from "../../utils/helper";
import { TableAirportRevenueData, AirportRevenueTableDataType } from "./types";
import Table from "../Table/Table";
import Pagination from "../Pagination/Pagination";
import { FilterSelectionType } from "../../pages/Home/types";
import moment from "moment";
import { Popover } from "antd";
import PopoverSelectOptionHolder from "../PopoverSelectOptionHolder/PopoverSelectOptionHolder";
import { useQuery } from "react-query";
import { getAirlineRevenue } from "../../services/home";
import { alternateRevenueChartDataType } from "../RevenueBreakDownCard/types";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";

import "./RevenueBreakDownPopup.scss";
import { toast } from "react-toastify";

interface RevenueBreakDownPopupType {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const RevenueBreakDownPopup = ({
  showModal,
  setShowModal,
}: RevenueBreakDownPopupType) => {
  const [currency, setCurrency] = useState("naira");
  const { control, watch } = useForm({
    defaultValues: { searchText: "" },
  });
  const searchText = watch("searchText").toLowerCase();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const tableHead: {
    name: keyof TableAirportRevenueData;
    displayName: string;
  }[] = [
    { name: "name", displayName: "Airport" },
    { name: "tscDomesticNaira", displayName: "TSC Dom" },
    { name: "tscInternationalNaira", displayName: "TSC Int" },
    { name: "pscDomestic", displayName: "PSC Dom" },
    { name: "pscRegional", displayName: "PSC Reg" },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPageNumber(page);
  };

  // Revenue Related Data Range
  const [revenueDataComparisonPeriod, setRevenueDataComparisonPeriod] =
    useState("Current Month");
  const [openRevenueDataDatePopOver, setOpenRevenueDatePopOver] =
    useState(false);
  const [revenueDataDateFilters, setRevenueFlightDataDateFilters] = useState({
    StartDate: moment().startOf("month").format("yy-MM-DD"),
    EndDate: moment().format("yy-MM-DD"),
  });

  const applyFilter = (params: FilterSelectionType, applyTo: string) => {
    const dateValue = params?.value as Date[];
    const StartDate = dateValue
      ? moment(dateValue[0]).format("yy-MM-DD")
      : applyTo === "flightData"
      ? moment().subtract(6, "days").format("yy-MM-DD")
      : moment().startOf("month").format("yy-MM-DD");
    const EndDate = dateValue
      ? moment(dateValue[1]).format("yy-MM-DD")
      : applyTo === "flightData"
      ? moment().format("yy-MM-DD")
      : moment().format("yy-MM-DD");

    const newFilters = {
      StartDate,
      EndDate,
    };
    setRevenueDataComparisonPeriod(params?.label);
    setRevenueFlightDataDateFilters(newFilters);
  };

  const { isLoading, data: comprehensiveAirlineRevenue, isError } = useQuery(
    [
      "comprehensiveAirlineRevenue",
      {
        revenueDataDateFilters,
      },
    ],
    () =>
      getAirlineRevenue({
        StartDate: revenueDataDateFilters?.StartDate,
        EndDate: revenueDataDateFilters?.EndDate,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );

  // Filter the data based on search term
  const filteredData = isLoading
    ? []
    : searchText === ""
    ? comprehensiveAirlineRevenue?.data?.airportRevenueTableData
    : comprehensiveAirlineRevenue?.data?.airportRevenueTableData?.filter(
        (airport: alternateRevenueChartDataType) => {
          return (
            airport.name.toLowerCase().includes(searchText) ||
            airport.code.toLowerCase().includes(searchText)
          );
        }
      );

  // Get the current page's data
  const paginatedData =
    isLoading || !filteredData
      ? []
      : filteredData.slice(
          (currentPageNumber - 1) * 10,
          currentPageNumber * 10
        );

  // Reset to page 1 when search term changes
  useEffect(() => {
    setCurrentPageNumber(1);
  }, [searchText]);

  return (
    <ModalContainer
      showModal={showModal}
      setShowModal={setShowModal}
      width={"80%"}>
      <div className="revenue-breakdown-popup">
        <h3>Revenue breakdown per airport</h3>
        <Popover
          content={
            <PopoverSelectOptionHolder
              applyRangeValue={applyFilter}
              selectOptions={RevenueDataFilterData[0].options}
              currentTextValue={revenueDataComparisonPeriod}
              setOpenRevenueDatePopOver={setOpenRevenueDatePopOver}
            />
          }
          trigger="click"
          placement="bottomLeft"
          open={openRevenueDataDatePopOver}
          onOpenChange={setOpenRevenueDatePopOver}
          overlayStyle={{ width: "200px" }}>
          <div>
            <OutlineButton
              icon={<CalenderIcon />}
              label={`${revenueDataComparisonPeriod}`}
              className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
            />
          </div>
        </Popover>
        {isLoading ? (
          <DataLoadingIndicator customStyling="bg-white rounded-[20px] aspect-[3/1] mt-6" />
        ) : isError ? (
          <NoDataIndicator
            customText="An error occurred, please try again later!"
            customStyling="bg-white rounded-[20px] aspect-[3/1] mt-6"
          />
        ) : comprehensiveAirlineRevenue?.data.length === 0 ? (
          <NoDataIndicator customStyling="bg-white rounded-[20px] aspect-[3/1] mt-6" />
        ) : (
          <div className="info-container">
            <div className="graph-holder">
              <div className="graph-top">
                <Heading
                  isSectionHeading={true}
                  headingText={"Top 5 airports"}
                  showToolTip={false}
                  headingToolTipText={""}
                />
                <CurrencyRadioSelector
                  currency={currency}
                  setCurrency={setCurrency}
                />
              </div>
              <div className="graph-part">
                {(currency === "naira" &&
                  comprehensiveAirlineRevenue?.data?.airportRevenues[0]?.values
                    .length === 0) ||
                (currency === "dollar" &&
                  comprehensiveAirlineRevenue?.data?.airportRevenues[1]?.values
                    .length === 0) ? (
                  <NoDataIndicator />
                ) : (
                  <RevenueBreakDownChart
                    chartData={
                      currency === "naira"
                        ? comprehensiveAirlineRevenue?.data?.airportRevenues[0]
                            ?.values
                        : comprehensiveAirlineRevenue?.data?.airportRevenues[1]
                            ?.values
                    }
                    currency={currency}
                    showColors={true}
                  />
                )}
              </div>
            </div>
            <div className="stats-holder">
              <div className="inner-tab">
                <StatsTextCard
                  amount={
                    comprehensiveAirlineRevenue?.data?.tscDomNaira?.amount
                  }
                  percentageGrowth={
                    comprehensiveAirlineRevenue?.data?.tscDomNaira
                      ?.percentageChange
                  }
                  isDecrease={
                    comprehensiveAirlineRevenue?.data?.tscDomNaira?.decrease
                  }
                  headingText={"TSC domestic"}
                  headingToolTipText={
                    "GMV revenue is based on data from NCAA TSC Domestic"
                  }
                  isSectionHeading={false}
                  currency={"naira"}
                />
              </div>
              <div className="inner-tab">
                <StatsTextCard
                  amount={
                    comprehensiveAirlineRevenue?.data?.tscDomDollar?.amount
                  }
                  percentageGrowth={
                    comprehensiveAirlineRevenue?.data?.tscDomDollar
                      ?.percentageChange
                  }
                  isDecrease={
                    comprehensiveAirlineRevenue?.data?.tscDomDollar?.decrease
                  }
                  showHeader={false}
                  headingText={""}
                  headingToolTipText={""}
                  isSectionHeading={false}
                  currency={"dollar"}
                />
              </div>
              <div className="inner-tab">
                <StatsTextCard
                  amount={
                    comprehensiveAirlineRevenue?.data?.tscIntlNaira?.amount
                  }
                  percentageGrowth={
                    comprehensiveAirlineRevenue?.data?.tscIntlNaira
                      ?.percentageChange
                  }
                  isDecrease={
                    comprehensiveAirlineRevenue?.data?.tscIntlNaira?.decrease
                  }
                  headingText={"TSC international"}
                  headingToolTipText={
                    "GMV revenue is based on data from NCAA TSC International."
                  }
                  isSectionHeading={false}
                  currency={"naira"}
                />
              </div>
              <div className="inner-tab">
                <StatsTextCard
                  amount={
                    comprehensiveAirlineRevenue?.data?.tscIntlDollar?.amount
                  }
                  percentageGrowth={
                    comprehensiveAirlineRevenue?.data?.tscIntlDollar
                      ?.percentageChange
                  }
                  isDecrease={
                    comprehensiveAirlineRevenue?.data?.tscIntlDollar?.decrease
                  }
                  showHeader={false}
                  headingText={""}
                  headingToolTipText={""}
                  isSectionHeading={false}
                  currency={"dollar"}
                />
              </div>
              <div className="inner-tab">
                <StatsTextCard
                  amount={
                    comprehensiveAirlineRevenue?.data?.pscDomestic?.amount
                  }
                  percentageGrowth={
                    comprehensiveAirlineRevenue?.data?.pscDomestic
                      ?.percentageChange
                  }
                  isDecrease={
                    comprehensiveAirlineRevenue?.data?.pscDomestic?.decrease
                  }
                  headingText={"PSC domestic"}
                  headingToolTipText={
                    "GMV revenue is based on data from FAAN's PSC Domestic."
                  }
                  isSectionHeading={false}
                  currency={"naira"}
                />
              </div>
              <div className="inner-tab">
                <StatsTextCard
                  amount={
                    comprehensiveAirlineRevenue?.data?.pscRegional?.amount
                  }
                  percentageGrowth={
                    comprehensiveAirlineRevenue?.data?.pscRegional
                      ?.percentageChange
                  }
                  isDecrease={
                    comprehensiveAirlineRevenue?.data?.pscRegional?.decrease
                  }
                  headingText={"PSC regional"}
                  headingToolTipText={
                    "GMV revenue is based on data from FAAN's PSC regional"
                  }
                  isSectionHeading={false}
                  currency={"dollar"}
                />
              </div>
            </div>
          </div>
        )}
        <div className="table-container">
          <div className="table-filter max-w-[350px]">
            <CustomInput
              leftIcon={<Magnifer />}
              leftIconStyle="z-50 -mt-1"
              placeholder="Filter by airport name or code"
              inputStyles="!pl-10 !outline-0"
              name="searchText"
              control={control}
              type="text"
            />
          </div>
          <div className="main-table-wrapper">
            <Table<TableAirportRevenueData, AirportRevenueTableDataType>
              fields={tableHead}
              tableData={paginatedData}
              isLoading={isLoading}
              rows={10}
              builder={(field, data) => {
                switch (field.name) {
                  case "name":
                    return <div className="w-full text-wrap">{data?.name}</div>;
                  case "tscDomesticNaira":
                    return (
                      <div>
                        {formatCurrency(data?.tscDomesticNaira, "naira") +
                          " | " +
                          formatCurrency(data?.tscDomesticDollar, "dollar")}
                      </div>
                    );
                  case "tscInternationalNaira":
                    return (
                      <div>
                        {formatCurrency(data?.tscInternationalNaira, "naira") +
                          " | " +
                          formatCurrency(
                            data?.tscInternationalDollar,
                            "dollar"
                          )}
                      </div>
                    );
                  case "pscDomestic":
                    return (
                      <div>{formatCurrency(data?.pscDomestic, "naira")}</div>
                    );
                  case "pscRegional":
                    return (
                      <div>{formatCurrency(data?.pscRegional, "dollar")}</div>
                    );
                  default:
                    return data[field?.name];
                }
              }}
            />
            {!isLoading && filteredData && (
              <div className="w-full mt-4">
                <Pagination
                  totalItems={filteredData?.length}
                  itemsPerPage={10}
                  onPageChange={handlePageChange}
                  currentPage={currentPageNumber}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default RevenueBreakDownPopup;
