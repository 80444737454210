import { AxiosRequestConfig } from 'axios'
import { getSavedState } from '../localStorage'

export const requestInteceptor = (config: AxiosRequestConfig) => {
    const savedState = getSavedState('NSIBUser')
    const nsibToken = savedState?.access_token

    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${nsibToken}`,
    }
    return config
}
