import { FC, ReactNode } from "react";
import "./LandingLayout.scss";
import backgrdImage from "../../assets/img/homeImg.png";

const LandingLayout: FC<{
  children: ReactNode;
  contentContainerClass?: string;
  disableScroll?: boolean;
}> = ({ children, contentContainerClass, disableScroll = true }) => {
  return (
    <div className="w-auto relative h-screen flex flex-row overflow-hidden">
      <div className="h-[100vh] bg-black/20 w-1/2 relative">
        <img
          className="object-cover w-full h-full"
          src={backgrdImage}
          alt="Background Image"
        />
        <div className="w-[45%] left-[5%] bottom-[10%] absolute text-white text-[40px] font-semibold font-['Archivo']">
          Smart analytics for your business
        </div>
        <div className="h-full w-[20px] absolute top-0 bottom-0 right-0 rounded-tl-[40px] rounded-bl-[40px] bg-white">

        </div>
      </div>

      {/* Content Section */}
      <div className="w-1/2 px-[10%] h-screen bg-white flex-col justify-start items-center inline-flex py-5">
        {children}
      </div>
    </div>
  );
};

export default LandingLayout;
