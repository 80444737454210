import { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";
import OutlineButton from "../../buttons/OutlineButton/OutlineButton";
import CustomInput from "../../UIKits/CustomInput/CustomInput";
import { PasswordManagementProps } from "../PasswordManagement/types";
import { userPasswordManagement } from "../../../validation-schemas/user-profile.schema";
import { Spinner } from "../../UIKits/Spinner/Spinner";


const PasswordManagement: FC<PasswordManagementProps> = ({
  currentPassword,
  newPassword,
  confirmPassword,
  handlePasswordChange,
  isLoading,
  clearPassword,
  openForgotModal,
}) => {


  const {
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { currentPassword, newPassword, confirmPassword },
    resolver: yupResolver(userPasswordManagement),
  });

  useEffect(() => {
    if (clearPassword) {
      setValue("currentPassword", "");
      setValue("newPassword", "");
      setValue("confirmPassword", "");
    }
  }, [
    clearPassword,
    currentPassword,
    newPassword,
    confirmPassword,
    setValue,
  ]);

  return (
    <div className="pl-1">
      <h1 className="lg:text-[24px] text-[20px] mb-[24px] lg:mb-[30px] mt-[20px]">
        Password
      </h1>
      <form className="w-[100%] lg:w-[80%]">
        <div className="mb-[20px] lg:w-[49%] w-[100%]">
          <CustomInput
            placeholder=""
            value={currentPassword}
            name="currentPassword"
            type="password"
            label="Current password"
            control={control}
            errorMessage={errors.currentPassword?.message}
          />
          <a href="#" className="flex justify-end link" onClick={openForgotModal}>
            Forgot password?
          </a>
        </div>

        <div className="lg:flex gap-[20px]">
          <CustomInput
            placeholder=""
            value={newPassword}
            name="newPassword"
            type="password"
            label="New password"
            inputContainerStyle={`mb-[20px]`}
            control={control}
            errorMessage={errors.newPassword?.message}
          />
          <CustomInput
            placeholder=""
            value={confirmPassword}
            name="confirmPassword"
            type="password"
            label="Retype new password"
            inputContainerStyle={`mb-[20px]`}
            control={control}
            errorMessage={errors.confirmPassword?.message}
          />
        </div>
        <div className="flex items-center gap-[12px]">
          <OutlineButton label="Cancel" onClick={()=>reset()} className="text-center !px-4 !py-2" />
          <PrimaryButton
            label={isLoading ? <Spinner /> : "Save"}
            onClick={handleSubmit(handlePasswordChange)}
            className="!px-5 !py-1.5"
          />
        </div>
      </form>
    </div>
  );
};

export default PasswordManagement;
