export const getSavedState = (itemKey: string) => {
  let savedState = {};
  try {
    let savedItem = localStorage.getItem(itemKey);
    if (typeof savedItem === "string") {
      return JSON.parse(savedItem);
    }
    return savedState;
  } catch (error) {
    return savedState;
  }
};

export function updateLocalState(itemKey: string, updatedData: any) {
  try {
    let savedItem = localStorage.getItem(itemKey);
    if (savedItem) {
      let parsedItem = JSON.parse(savedItem);
      const updatedItem = {
        ...parsedItem,
        ...updatedData,
      };
      localStorage.setItem(itemKey, JSON.stringify(updatedItem));
    } else {
    }
  } catch (error) {}
}

export async function saveLocalState(itemKey: string, itemValue: any) {
  localStorage.setItem(itemKey, JSON.stringify(itemValue));
}

export function deleteLocalState(itemKey: string) {
  localStorage.removeItem(itemKey);
}
