import { useState } from "react";
import OutlineButton from "../../components/buttons/OutlineButton/OutlineButton";
import PassengerFlightCard from "../../components/PassengerFlightCard/PassengerFlightCard";
import RevenueBreakDownCard from "../../components/RevenueBreakDownCard/RevenueBreakDownCard";
import TopGeneratingAirlinesCard from "../../components/TopGeneratingAirlinesCard/TopGeneratingAirlinesCard";
import TopRoutesCard from "../../components/TopRoutesCard/TopRoutesCard";
import TscPscCard from "../../components/TscPscCard/TscPscCard";
import { ReactComponent as CalenderIcon } from "../../assets/icons/calender.svg";
import NsibLogo from "../../assets/img/nsib-logo.png";
import InsightSlider from "../../components/InsightSlider/InsightSlider";
import ProfileSwitch from "../../components/ProfileSwitch/ProfileSwitch";
import { getSavedState } from "../../utils/localStorage";
import LogoutPopover from "../../components/LogoutCard/LogoutCard";
import { Popover } from "antd";
import moment from "moment";
import DateRangeTwo from "../../components/DateRangeTwo/DateRangeTwo";

import "./Home.scss";
import PopoverSelectOptionHolder from "../../components/PopoverSelectOptionHolder/PopoverSelectOptionHolder";
import { FilterSelectionType } from "./types";
import { RevenueDataFilterData } from "../../data/samples";

const Home = () => {
  const [open, setOpen] = useState(false);
  const user = getSavedState("NSIBUser");
  const fullName = user?.fullName || "";

  const handleProfileSwitchClick = () => {
    setOpen(!open);
  };

  // Functions to handle Date
  // Revenue Related Data Range
  const [revenueDataComparisonPeriod, setRevenueDataComparisonPeriod] =
    useState("Current Month");
  const [openRevenueDataDatePopOver, setOpenRevenueDatePopOver] =
    useState(false);

  const [revenueDataDateFilters, setRevenueFlightDataDateFilters] = useState({
    StartDate: moment().startOf("month").format("yy-MM-DD"),
    EndDate: moment().format("yy-MM-DD"),
  });

  // Flight Data Range
  const [openFlightDataDatePopOver, setOpenFlightDatePopOver] = useState(false);
  const [flightDataComparisonPeriod, setFlightDataComparisonPeriod] =
    useState("Last 7 days");

  const [flightDataDateFilters, setFlightDataDateFilters] = useState({
    StartDate: moment().subtract(6, "days").format("yy-MM-DD"),
    EndDate: moment().format("yy-MM-DD"),
  });

  const applyFilter = (params: FilterSelectionType, applyTo: string) => {
    const dateValue = params?.value as Date[];
    const StartDate = dateValue
      ? moment(dateValue[0]).format("yy-MM-DD")
      : applyTo === "flightData"
      ? moment().subtract(6, "days").format("yy-MM-DD")
      : moment().startOf("month").format("yy-MM-DD");
    const EndDate = dateValue
      ? moment(dateValue[1]).format("yy-MM-DD")
      : applyTo === "flightData"
      ? moment().format("yy-MM-DD")
      : moment().format("yy-MM-DD");

    const newFilters = {
      StartDate,
      EndDate,
    };
    if (applyTo === "flightData") {
      setFlightDataComparisonPeriod(params?.label);
      setFlightDataDateFilters(newFilters);
    } else {
      setRevenueDataComparisonPeriod(params?.label);
      setRevenueFlightDataDateFilters(newFilters);
    }
  };

  return (
    <div className="home-page">
      <div className="content-container">
        <div className="logo-container">
          <img src={NsibLogo} alt="NSIB Logo" className="nsib-logo" />
          <Popover
            content={<LogoutPopover />}
            trigger="click"
            open={open}
            onOpenChange={setOpen}
            placement="bottomRight"
            overlayStyle={{ padding: 0, width: "auto"}}
            >
            <div>
              <ProfileSwitch
                // profileInitial={fullName}
                onClick={handleProfileSwitchClick}
              />
            </div>
          </Popover>
        </div>
        <div className="welcome-container">
          <h1>Welcome back {fullName}</h1>
          <p>Here is a summary of your business activity</p>
        </div>
        <div className="section-header">
          <h2>Revenue</h2>
          <Popover
            content={
              <PopoverSelectOptionHolder
                applyRangeValue={applyFilter}
                selectOptions={RevenueDataFilterData[0].options}
                currentTextValue={revenueDataComparisonPeriod}
                setOpenRevenueDatePopOver={setOpenRevenueDatePopOver}
              />
            }
            trigger="click"
            placement="bottomLeft"
            open={openRevenueDataDatePopOver}
            onOpenChange={setOpenRevenueDatePopOver}
            overlayStyle={{ width: "200px", }}>
            <div>
              <OutlineButton
                icon={<CalenderIcon />}
                label={`${revenueDataComparisonPeriod}`}
                className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
              />
            </div>
          </Popover>
        </div>
        <div className="w-full grid grid-cols-[30%_1fr] gap-5">
          <InsightSlider />
          <TscPscCard dateFilter={revenueDataDateFilters} />
        </div>
        <div className="w-full grid grid-cols-[35%_1fr] gap-5 mt-6">
          <TopGeneratingAirlinesCard dateFilter={revenueDataDateFilters} />
          <RevenueBreakDownCard dateFilter={revenueDataDateFilters} />
        </div>
        <div className="section-header relative">
          <h2>Flight data</h2>
          <Popover
            content={
              <DateRangeTwo
                applyRangeValue={applyFilter}
                setOpenDatePopOver={setOpenFlightDatePopOver}
              />
            }
            trigger="click"
            open={openFlightDataDatePopOver}
            onOpenChange={setOpenFlightDatePopOver}
            className="popover-container"
            placement="bottomRight"
            overlayStyle={{ width: "fit-content" }}>
            <div>
              <OutlineButton
                label={flightDataComparisonPeriod}
                icon={<CalenderIcon />}
                className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
              />
            </div>
          </Popover>
        </div>
        <div className="w-full grid grid-cols-[1fr_35%] gap-5">
          <PassengerFlightCard dateFilter={flightDataDateFilters} />
          <TopRoutesCard dateFilter={flightDataDateFilters} />
        </div>
      </div>
    </div>
  );
};

export default Home;
