import PscTscChart from "../charts/PscTscChart/PscTscChart";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useState } from "react";
import StatsTextCard from "../StatsTextCard/StatsTextCard";
import CurrencyRadioSelector from "../CurrencyRadioSelector/CurrencyRadioSelector";
import { DateFilterType } from "../../pages/Home/types";
import { useQuery } from "react-query";
import {
  getPscDomData,
  getPscRegData,
  getTscDomIntData,
} from "../../services/home";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import "./TscPscCard.scss";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";
import { toast } from "react-toastify";

const TscPscCard = ({ dateFilter }: { dateFilter: DateFilterType }) => {
  const [tscPscSwitchValue, setTscPscSwitchValue] = useState("tsc");
  const [currency, setCurrency] = useState("naira");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const possibleSwitchValues = ["tsc", "psc"];

  const switchActiveTab = (index: number) => {
    setActiveTabIndex(index);
    if (tscPscSwitchValue === "psc" && index === 1) {
      setCurrency("dollar");
    }
    if (tscPscSwitchValue === "psc" && index === 0) {
      setCurrency("naira");
    }
  };
  const handleToggle = (value: string) => {
    if (value !== tscPscSwitchValue) {
      setTscPscSwitchValue(value);
      setActiveTabIndex(0);
      setCurrency("naira");
    }
  };

  const handleCurrencySwitch = (currency: string) => {
    setCurrency(currency);
  };

  const {
    isLoading: isTscDomLoading,
    data: tscDomData,
    isError: isTscDomError,
  } = useQuery(
    [
      "TscDomData",
      {
        dateFilter,
      },
    ],
    () =>
      getTscDomIntData({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
        isLocal: true,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );
  const {
    isLoading: isTscIntLoading,
    data: tscIntData,
    isError: isTscIntError,
  } = useQuery(
    [
      "TscIntData",
      {
        dateFilter,
      },
    ],
    () =>
      getTscDomIntData({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
        isLocal: false,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );
  const {
    isLoading: isPscDomLoading,
    data: pscDomData,
    isError: isPSCDomError,
  } = useQuery(
    [
      "PscDomData",
      {
        dateFilter,
      },
    ],
    () =>
      getPscDomData({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );
  const {
    isLoading: isPscRegLoading,
    data: pscRegData,
    isError: isPscRegError,
  } = useQuery(
    [
      "PscRegData",
      {
        dateFilter,
      },
    ],
    () =>
      getPscRegData({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
        IsRegional: true,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );

  return (
    <div className="tsc-psc-card w-full">
      {isTscDomLoading ||
      isTscIntLoading ||
      isPscRegLoading ||
      isPscDomLoading ? (
        <DataLoadingIndicator />
      ) : isTscDomError || isTscIntError || isPSCDomError || isPscRegError ? (
        <NoDataIndicator customText="An error occurred, please try again later!" />
      ) : (
        <>
          <div className="">
            <div className="switch-holder">
              <ToggleSwitch
                setSwitchValue={handleToggle}
                switchValues={possibleSwitchValues}
                currentValue={tscPscSwitchValue}
              />
            </div>
            <div className="tab-controller">
              <div
                onClick={() => switchActiveTab(0)}
                className={`control-tab ${
                  activeTabIndex === 0 ? "active" : ""
                }`}>
                <div className="inner-tab">
                  <StatsTextCard
                    amount={
                      tscPscSwitchValue === "tsc"
                        ? tscDomData?.data?.values?.naira?.amount
                        : pscDomData?.data?.values?.naira?.amount
                    }
                    percentageGrowth={
                      tscPscSwitchValue === "tsc"
                        ? tscDomData?.data?.values?.naira?.percentageGrowth
                        : pscDomData?.data?.values?.naira?.percentageGrowth
                    }
                    isDecrease={
                      tscPscSwitchValue === "tsc"
                        ? tscDomData?.data?.values?.naira?.decrease
                        : pscDomData?.data?.values?.naira?.decrease
                    }
                    headingText={
                      tscPscSwitchValue === "tsc"
                        ? "TSC Domestic"
                        : "PSC Domestic"
                    }
                    headingToolTipText={
                      tscPscSwitchValue === "tsc"
                        ? "GMV revenue is based on data from NCAA TSC Domestic."
                        : "GMV revenue is based on data from FAAN's PSC domestic."
                    }
                    isSectionHeading={false}
                  />
                </div>
                {tscPscSwitchValue !== "tsc" ? null : (
                  <div className="inner-tab">
                    <StatsTextCard
                      amount={tscDomData?.data?.values?.dollar?.amount}
                      percentageGrowth={
                        tscDomData?.data?.values?.dollar?.percentageGrowth
                      }
                      isDecrease={tscDomData?.data?.values?.dollar?.decrease}
                      showHeader={false}
                      headingText={""}
                      headingToolTipText={""}
                      isSectionHeading={false}
                      currency={"dollar"}
                    />
                  </div>
                )}
              </div>
              <div
                onClick={() => switchActiveTab(1)}
                className={`control-tab ${
                  activeTabIndex === 1 ? "active" : ""
                }`}>
                {tscPscSwitchValue !== "tsc" ? null : (
                  <div className="inner-tab">
                    <StatsTextCard
                      amount={tscIntData?.data?.values?.naira?.amount}
                      percentageGrowth={
                        tscIntData?.data?.values?.naira?.percentageGrowth
                      }
                      isDecrease={tscIntData?.data?.values?.naira?.decrease}
                      headingText={"TSC International"}
                      headingToolTipText={
                        "GMV revenue is based on data from NCAA TSC International."
                      }
                      isSectionHeading={false}
                    />
                  </div>
                )}
                <div className="inner-tab">
                  <StatsTextCard
                    amount={
                      tscPscSwitchValue === "tsc"
                        ? tscIntData?.data?.values?.dollar?.amount
                        : pscRegData?.data?.values?.dollar?.amount
                    }
                    percentageGrowth={
                      tscPscSwitchValue === "tsc"
                        ? tscIntData?.data?.values?.dollar?.percentageGrowth
                        : pscRegData?.data?.values?.dollar?.percentageGrowth
                    }
                    isDecrease={
                      tscPscSwitchValue === "tsc"
                        ? tscIntData?.data?.values?.dollar?.decrease
                        : pscRegData?.data?.values?.dollar?.decrease
                    }
                    headingText={
                      tscPscSwitchValue === "tsc" ? " " : "PSC Regional"
                    }
                    headingToolTipText={
                      tscPscSwitchValue === "tsc"
                        ? ""
                        : "GMV revenue is based on data from FAAN's PSC regional"
                    }
                    isSectionHeading={false}
                    currency={"dollar"}
                    showHeader={tscPscSwitchValue === "tsc" ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="currency-controller">
            {tscPscSwitchValue === "tsc" ? (
              <div className="currency-switch">
                <CurrencyRadioSelector
                  currency={currency}
                  setCurrency={handleCurrencySwitch}
                />
              </div>
            ) : null}

            <PscTscChart
              currency={currency}
              chartData={
                tscPscSwitchValue === "tsc" &&
                activeTabIndex === 0 &&
                currency === "naira"
                  ? tscDomData?.data?.naira
                  : tscPscSwitchValue === "tsc" &&
                    activeTabIndex === 0 &&
                    currency === "dollar"
                  ? tscDomData?.data?.dollar
                  : tscPscSwitchValue === "tsc" &&
                    activeTabIndex === 1 &&
                    currency === "naira"
                  ? tscIntData?.data?.naira
                  : tscPscSwitchValue === "tsc" &&
                    activeTabIndex === 1 &&
                    currency === "dollar"
                  ? tscIntData?.data?.dollar
                  : tscPscSwitchValue === "psc" && activeTabIndex === 0
                  ? pscDomData?.data?.naira
                  : tscPscSwitchValue === "psc" && activeTabIndex === 1
                  ? pscRegData?.data?.dollar
                  : tscDomData?.data?.naira
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TscPscCard;
