import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  Rectangle,
} from "recharts";
import { RevenueChartDataType } from "../../RevenueBreakDownCard/types";

import "./RevenueBreakDownChart.scss";
import CustomToolTip from "../common/CustomToolTip/CustomToolTip";

interface RevenueBreakDownChartType {
  chartData: RevenueChartDataType[];
  currency: string;
  showColors?: boolean;
}

const RevenueBreakDownChart = ({
  chartData,
  currency,
  showColors = false,
}: RevenueBreakDownChartType) => {
  const barColors = ["#623BFA", "#A28BFF", "#FA7B38", "#FFA16F"];

  return (
    <div className="revenue-breakdown-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <XAxis dataKey="code" stroke="#fff" tick={{ fill: "#000000B2" }} />
          <Tooltip
            cursor={{ fill: "transparent", cursor: "pointer" }}
            content={
              <CustomToolTip
                active={true}
                payload={[]}
                label=""
                currency={currency}
                showDataName={true}
                showTotal={true}
              />
            }
          />
          {["tscDomestic", "tscInternational", "pscDomestic", "pscRegional"].map((key, idx) => (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              fill={showColors ? barColors[idx] : "#F3F3F3"}
              radius={10}
              style={{ stroke: "#fff", strokeWidth: 2 }}
              activeBar={<Rectangle fill={barColors[idx]} />}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RevenueBreakDownChart;
