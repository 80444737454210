import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "./routes";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/Resetpassword";
// const ForgotPasswordPage = lazy(
//   () => import("../pages/ForgotPassword/ForgotPassword")
// );


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />

      {routes.map((route) => (
        <Route key={route.id} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
