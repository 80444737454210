import { formatCurrency } from "../../utils/helper";
import { ReactComponent as UpwardArrow } from "../../assets/icons/uparrow.svg";
import Heading from "../Heading/Heading";

import "./StatsTextCard.scss";

interface StatsTextType {
  amount: number;
  percentageGrowth: number;
  isDecrease?: boolean;
  headingText: string;
  headingToolTipText: string;
  isSectionHeading?: boolean;
  currency?: string;
  showHeader?: boolean;
  isMoney?: boolean;
}

const StatsTextCard = ({
  amount,
  percentageGrowth,
  isDecrease,
  headingText,
  headingToolTipText,
  isSectionHeading,
  currency,
  showHeader = true,
  isMoney = true,
}: StatsTextType) => {
  return (
    <div className={`stats-text-card ${isMoney ? "" : "not-money"}`}>
      <Heading
        headingText={headingText}
        headingToolTipText={headingToolTipText}
        isSectionHeading={isSectionHeading}
        showHeader={showHeader}
      />
      {isMoney ? (
        <p
          className="amount"
          >
          {formatCurrency(amount, currency)}
        </p>
      ) : (
        <p className="amount not-cash">{amount}</p>
      )}
      <p className={`growth-stat ${isDecrease ? "decrease" : ""}`}>
        <span>
          {percentageGrowth}% <UpwardArrow className="arrow-icon" />
        </span>
        from last period
      </p>
    </div>
  );
};

export default StatsTextCard;
