import { useRef } from "react";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { DateRangeProps } from "./types";

import "./DateRangeTwo.scss";

const DateRangeTwo = ({
  handleDateSelect,
  applyRangeValue,
  placeholder = "Date",
  styles,
  setOpenDatePopOver,
}: DateRangeProps) => {
  const predefinedRanges: any = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
  ];

  const dateRangeRef = useRef<HTMLDivElement>(null);

  const handlewithinComponentDateSelect = (label: string, value: Date[]) => {
    const dateValue = { label: label, value: value };
    applyRangeValue(dateValue, "flightData");
    setOpenDatePopOver(false);
  };

  return (
    <div className="desktop-date-range" ref={dateRangeRef}>
      <DateRangePicker
        container={() => dateRangeRef.current as HTMLElement}
        ranges={predefinedRanges}
        placeholder={placeholder}
        style={{ ...styles }}
        character="-"
        open
        block
        placement="bottom"
        onShortcutClick={(shortcut) => {
          handlewithinComponentDateSelect(
            shortcut.label as string,
            shortcut?.value as Date[]
          );
        }}
        onOk={(dateRange) => {
          handlewithinComponentDateSelect("Custom Range", dateRange);
        }}
      />
    </div>
  );
};

export default DateRangeTwo;
