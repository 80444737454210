import { Select } from "antd";
import moment from "moment";
import { useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton/PrimaryButton";
import OutlineButton from "../buttons/OutlineButton/OutlineButton";
import { FilterSelectionType } from "../../pages/Home/types";
import { toast } from "react-toastify";

const { Option } = Select;

interface CustomDateRangeType {
  applyRangeValue: (dateInfo: FilterSelectionType, applyTo: string) => void;
  handleClosePopOver: () => void;
}

const CustomDateRange = ({
  applyRangeValue,
  handleClosePopOver,
}: CustomDateRangeType) => {
  const defaultYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState<number>(defaultYear);
  const [endYear, setEndYear] = useState<number>(defaultYear);
  const [startMonth, setStartMonth] = useState<number | undefined>();
  const [endMonth, setEndMonth] = useState<number | undefined>();

  // Generate years for selection
  const years = Array.from({ length: 10 }, (_, i) => defaultYear - 9 + i);

  // Months array
  const months = moment.months().map((month, index) => ({
    name: month,
    value: index,
  }));

  // Handlers
  const handleStartMonthChange = (value: number) => {
    setStartMonth(value);
    // Reset end month if it's before the start month in the same year
    if (endYear === startYear && endMonth !== undefined && value > endMonth) {
      setEndMonth(undefined);
    }
  };

  const handleEndMonthChange = (value: number) => {
    setEndMonth(value);
  };

  const handleApplyDate = () => {
    if (startMonth !== undefined && endMonth !== undefined) {
      const startDate = new Date(startYear, startMonth, 1); // First day of start month
      const endDate = new Date(endYear, endMonth + 1, 0); // Last day of end month

      // Check if the end date is before the start date
      if (endDate < startDate) {
        toast.error("End date cannot come before start date");
        return;
      }

      // Calculate the month difference
      const monthDiff =
        (endYear - startYear) * 12 + (endMonth - startMonth + 1); // +1 to include the end month

      if (monthDiff > 12) {
        toast.error("Maximum range is 12 months");
        return;
      }

      applyRangeValue(
        {
          label: "Custom Range",
          value: [startDate, endDate],
        },
        "revenueData"
      );
      handleClosePopOver();
    } else {
      toast.error("Select a valid date range");
    }
  };

  return (
    <div className="p-3 w-[350px]">
      <div className="w-full grid grid-cols-2 gap-2 mb-4">
        <span className="text-[#171C26] font-[Archivo] font-medium text-sm mb-0">
          Year
        </span>
        <span className="text-[#171C26] font-[Archivo] font-medium text-sm mb-0">
          End year
        </span>
        <Select
          className="w-full"
          value={startYear}
          onChange={(value) => {
            setStartYear(value);
            if (value > endYear) {
              setEndYear(value); // Ensure end year is not less than start year
              setEndMonth(undefined); // Reset the end month if years change
            }
          }}>
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
        <Select
          className="w-full"
          value={endYear}
          onChange={(value) => {
            setEndYear(value);
            if (value < startYear) {
              setStartYear(value);
              setStartMonth(undefined);
            }
          }}>
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </div>
      <div className="w-full grid grid-cols-2 gap-2 mb-11">
        <span className="text-[#171C26] font-[Archivo] text-sm mb-0">
          Start month
        </span>
        <span className="text-[#171C26] font-[Archivo] text-sm mb-0">
          End month
        </span>
        <Select
          value={startMonth}
          onChange={handleStartMonthChange}
          className="w-full"
          placeholder="Start Month">
          {months.map(({ name, value }) => (
            <Option key={value} value={value}>
              {name}
            </Option>
          ))}
        </Select>
        <Select
          value={endMonth}
          onChange={handleEndMonthChange}
          className="w-full"
          placeholder="End Month"
          disabled={startMonth === undefined}>
          {months.map(({ name, value }) => (
              <Option key={value} value={value}>
                {name}
              </Option>
            ))}
        </Select>
      </div>
      <div className="flex gap-3 justify-end items-center">
        <OutlineButton
          label={"Close"}
          className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
          onClick={handleClosePopOver}
        />
        <PrimaryButton
          label={"Apply date"}
          className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
          disabled={!endMonth}
          onClick={() => {
            handleApplyDate();
          }}
        />
      </div>
    </div>
  );
};

export default CustomDateRange;
