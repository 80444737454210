import { devInstance } from "../utils/axios/axiosInstance";

export const getInsightData = async () => {
  const response = await devInstance.get("/Dashboard/GetInsightSummary");
  return response.data;
};
export const getTscDomIntData = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/GetTSCGMVSummary", {
    params: payload,
  });
  return response.data;
};
export const getPscDomData = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/GetPSCGMVSummary", {
    params: payload,
  });
  return response.data;
};
export const getPscRegData = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/GetPSCRegionalGMVSummary", {
    params: payload,
  });
  return response.data;
};

export const getAirlineRevenue = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/RevenuePerAirport", {
    params: payload,
  });
  return response.data;
};
export const getTopFourAirlines = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/Top4Airlines", {
    params: payload,
  });
  return response.data;
};
export const getPassengerFlightCount = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/PassengerFlightCount", {
    params: payload,
  });
  return response.data;
};
export const getPassengerFlightCountDetail = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/PassengerFlightCountDetails", {
    params: payload,
  });
  return response.data;
};
export const getTopFlightRoutes = async (payload: any) => {
  const response = await devInstance.get("/Dashboard/TopAirlineRoutes", {
    params: payload,
  });
  return response.data;
};





