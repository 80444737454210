import { useState } from "react";
import TopRoutePieChart from "../charts/TopRoutePieChart/TopRoutePieChart";
import Heading from "../Heading/Heading";
import { DateFilterType } from "../../pages/Home/types";
import { useQuery } from "react-query";
import { getTopFlightRoutes } from "../../services/home";
import { PieChartDataListType } from "./types";
import { getPercentageContributionFromTotal } from "../../utils/helper";

import "./TopRoutesCard.scss";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";
import { toast } from "react-toastify";

const TopRoutesCard = ({ dateFilter }: { dateFilter: DateFilterType }) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const {
    isLoading,
    data: topFlightRoutes,
    isError,
  } = useQuery(
    [
      "topFlightRoutes",
      {
        dateFilter,
      },
    ],
    () =>
      getTopFlightRoutes({
        StartDate: dateFilter?.StartDate,
        EndDate: dateFilter?.EndDate,
      }),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message || "An error has occurred, please contact system admin!"
        );
      },
    }
  );

  // Filter response and get the top 5
  const top5Routes: PieChartDataListType[] =
    topFlightRoutes?.data?.data.length === 0 || isLoading
      ? []
      : topFlightRoutes?.data?.data
          .sort(
            (a: PieChartDataListType, b: PieChartDataListType) =>
              b.value - a.value
          )
          .slice(0, 5) || [];

  // Get their total to help get the percentage stuff
  const sumTotal =
    top5Routes.reduce(
      (sum: number, route: PieChartDataListType) => sum + route.value,
      0
    ) || 0;

  return (
    <div className="top-routes-card">
      <Heading
        isSectionHeading={true}
        headingText={"Top routes"}
        headingToolTipText={
          "This data is sourced from the NCAA TSC Domestic Database"
        }
      />

      {isLoading ? (
        <DataLoadingIndicator />
      ) : isError ? (
        <NoDataIndicator customText="An error occurred, please try again later!" />
      ) : topFlightRoutes?.data?.data.length === 0 ? (
        <NoDataIndicator />
      ) : (
        <div className="chart-container">
          <TopRoutePieChart
            chartData={top5Routes}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            sumTotal={sumTotal}
          />
          <div className="data-points">
            {top5Routes?.map((element: PieChartDataListType, id: number) => (
              <div
                className={`data-info ${
                  activeIndex !== -1
                    ? activeIndex === id
                      ? "show"
                      : "opac"
                    : ""
                }`}
                key={id}>
                <div className="data-name">
                  <div className={`marker marker${id}`}></div>
                  <span className="name">{element?.name}</span>
                </div>
                <span className="data-value">
                  {element?.value}{" "}
                  <span className="percentage-contribution">
                    (
                    {getPercentageContributionFromTotal(
                      sumTotal,
                      element.value
                    )}
                    %)
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TopRoutesCard;
