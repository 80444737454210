import { FC } from "react";
import { ProfileImageProps } from "./types";
import { getFullNameInitials } from "../../../utils/helper";

import "./ProfileImage.scss";

const ProfileImage: FC<ProfileImageProps> = ({
  fullName,
  width = "120px",
  height = "120px",
  size = "40px",
  avatarProfile,
}) => {
  return (
    <div
      className="avatarPlaceholderText overflow-hidden"
      // style={{ width, height, fontSize: size }}
    >
      {avatarProfile ? (
        <div className={`w-[100px] h-[100px] ${width}`}>
          <img className="w-full" src={avatarProfile} alt="avatar" />
        </div>
      ) : (
        <p
          style={{ width, height, fontSize: size }}
          className="py-[40px] px-[27px] text-nowrap"
        >
          {getFullNameInitials(fullName)}
        </p>
      )}
    </div>
  );
};

export default ProfileImage;
