const NoDataIndicator = ({
  customStyling,
  customText,
}: {
  customStyling?: string;
  customText?: string;
}) => {
  return (
    <div
      className={`w-full h-full ${
        customStyling ? customStyling : "aspect-auto"
      } flex items-center justify-center`}>
      <p className="font-[Archivo] text-[#1A1B1D99] -mt-10">
        {customText ? customText : "No data to show"}
      </p>
    </div>
  );
};

export default NoDataIndicator;
