import "./CustomLegend.scss";

interface CustomLegendPropType {
  payload: any[];
  flattenMarker?: boolean;
}

const CustomLegend = ({
  payload,
  flattenMarker = false,
}: CustomLegendPropType) => {
  return (
    <div className="custom-legend">
      <div>
        
      </div>
      {payload.map((entry, id) => (
        <div className="marker-container" key={id}>
          <span
            className={`marker${id} marker ${
              flattenMarker ? "flatten" : "normal"
            }`}></span>
          <p>{id === 0 ? "Chosen Period" : "Last Period"}</p>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
