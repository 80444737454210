import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { userProfileValidationSchema } from "../../../validation-schemas/user-profile.schema";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";
import OutlineButton from "../../buttons/OutlineButton/OutlineButton";
import CustomInput from "../../UIKits/CustomInput/CustomInput";
import ProfileImage from "../ProfileImage/ProfileImage";
import { ReactComponent as CameraIcon } from "../../../assets/icons/camera.svg";
import { Spinner } from "../../UIKits/Spinner/Spinner";
import { Button, Upload } from "antd";
import { useMutation } from "react-query";
import { uploadProfilePicture } from "../../../services/settings";
import { toast } from "react-toastify";

interface UserProfileProp {
  userProfile: {
    fullName: string;
    phone: string;
    email: string;
    profilePicture: string;
  };
  handleSubmitForm: (values: any) => void;
  profileUpdateLoading: boolean;
}

const UserProfile: FC<UserProfileProp> = ({
  userProfile,
  handleSubmitForm,
  profileUpdateLoading,
}) => {
  const [uploadUrl, setUploadUrl] = useState<string>(
    userProfile.profilePicture || ""
  );
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...userProfile },
    resolver: yupResolver(userProfileValidationSchema),
  });

  const triggerFilePicker = () => {
    document.getElementById("file-input")?.click();
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: uploadProfilePicture,
    onSuccess: (response) => {
      if (response.statusCode === 200) {
        toast.success(`${response.message}`);
        setUploadUrl(response.data);
      }
    },
    onError: () => {
      toast.error("Failed to upload profile picture.");
    },
  });

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    await mutate(file);
  };

  return (
    <div className="border-b border-[#F0F0F0] pb-[44px] pl-1">
      <div className="flex items-center mt-[32px] mb-[30px]">
        <ProfileImage
          avatarProfile={uploadUrl}
          fullName={userProfile.fullName}
        />

        <Upload
          customRequest={customRequest}
          showUploadList={false}
          className="ml-[18px]"
          accept=".jpeg,.jpg,.png"
        >
          <Button icon={<CameraIcon />} loading={isLoading}>
            Change
          </Button>
        </Upload>
      </div>

      <form className="lg:w-[40%] w-[100%] pl-1">
        <CustomInput
          placeholder="Raymond Jackson"
          value={userProfile.fullName}
          name="fullName"
          type="text"
          label="Full name"
          inputContainerStyle={`mb-[20px]`}
          inputStyles="w-full"
          control={control}
          errorMessage={errors.fullName?.message}
        />
        <CustomInput
          placeholder="email@email.com"
          value={userProfile.email}
          name="email"
          type="email"
          label="Email"
          inputContainerStyle={`mb-[20px]`}
          control={control}
          isDisabled
          errorMessage={errors.email?.message}
        />

        <div className="flex items-center gap-[12px]">
          <OutlineButton label="Cancel" className="text-center !px-4 !py-2" onClick={reset} />
          <PrimaryButton
          disabled={profileUpdateLoading}
            label={profileUpdateLoading ? <Spinner /> : "Save"}
            onClick={handleSubmit(handleSubmitForm)}
            className="!px-5 !py-1.5"
          />
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
