import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import ModalContainer from "../Modal/Modal";
import { fetchUserProfile, selfUpdate } from "../../services/settings";
import { AxiosError } from "axios";
import { Spinner } from "../UIKits/Spinner/Spinner";
import PasswordManagement from "./PasswordManagement/PasswordManagement";
import UserProfile from "./UserProfile/UserProfile";
import { changePassword } from "../../services/auth";
import ForgotPasswordModal from "./ForgotPasswordModal/ForgotPasswordModal";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import { deleteLocalState, updateLocalState } from "../../utils/localStorage";

interface settingsPropType {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const SettingsContainer = ({ showModal, setShowModal }: settingsPropType) => {
  const [userProfile, setUserProfile] = useState({
    fullName: "",
    email: "",
    phone: "",
    profilePicture: "",
  });
  const [userPassword, setUserPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [clearPassword, setClearPassword] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);

  // Fetch user profile
  const {
    isFetching: isFetchingProfile,
    isLoading: isLoadingProfile,
    data: response,
    error,
    refetch,
  } = useQuery(["profile"], fetchUserProfile, {
    enabled: false,
    retry: true,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setUserProfile({
          fullName: `${res.data.firstName} ${res.data.lastName}`,
          email: res.data.email,
          phone: res.data.phoneNum,
          profilePicture: res.data.profilePicture || "",
        });
      } else {
        toast.error(res.message || "Can't fetch profile at the moment.");
      }
    },
    onError: (err: AxiosError) => {
      toast.error("Error fetching profile.");
    },
  });

  // Update profile
  const { mutate: updateProfile, isLoading: profileUpdateLoading } =
    useMutation(selfUpdate, {
      onSuccess: (response, variables) => {
        if (response.statusCode === 200) {
          toast.success("Profile updated successfully, reloading page!");
          updateLocalState("NSIBUser", {"fullName": variables?.firstName + " " + variables?.lastName})
          refetch();
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      },
      onError: () => {
        toast.error("Failed to update profile.");
      },
    });

  const handleSubmitForm = (values: any) => {
    const [firstName, lastName] = values.fullName.split(" ");
    const updatedValues = {
      firstName: firstName || "",
      lastName: lastName || "",
      phoneNumber: values.phone,
    };
    updateProfile(updatedValues);

  };

  // Change password
  const { mutate: changeUserPassword } = useMutation(changePassword, {
    onSuccess: (response) => {
      const successMessage = response?.data?.message || "Password changed successfully!";
      toast.success(successMessage);
      setClearPassword(true); 
      setIsChangingPassword(false);
    },
    onError: (error: AxiosError) => {
      // @ts-ignore
      const errorMessage = error?.response?.data?.message || "Failed to change password.";
      toast.error(errorMessage);
      setIsChangingPassword(false);
    },
  });
  


  const handlePasswordChange = (passwordValues: any) => {
    setIsChangingPassword(true);
    changeUserPassword(passwordValues);
  };

  const openForgotModal = () => {
    setShowForgotModal(true);
  };

  useEffect(() => {
    if (showModal) {
      refetch();
    }
  }, [showModal, refetch]);

  return (
    <>
      {showModal && !showForgotModal && (<ModalContainer showModal={showModal} setShowModal={setShowModal}>
        
          <div className="border-b border-[#F0F0F0] pl-6">
            <div className="text-[#0d0d0d] text-2xl font-semibold font-['Archivo']">
              Settings
            </div>
            <div className={`${isFetchingProfile ? "" :  "pb-[107px]"}  overflow-hidden`}>
              {isFetchingProfile ? (
                <DataLoadingIndicator customStyling="aspect-[2/1]" />
              ) : (
                <>
                  <UserProfile
                    handleSubmitForm={handleSubmitForm}
                    userProfile={userProfile}
                    profileUpdateLoading={profileUpdateLoading}
                  />
                  <PasswordManagement
                  currentPassword={userPassword.currentPassword}
                  newPassword={userPassword.newPassword}
                  confirmPassword={userPassword.confirmPassword}
                  handlePasswordChange={handlePasswordChange}
                  isLoading={isChangingPassword}
                  clearPassword={clearPassword}
                  openForgotModal={openForgotModal}
                />
                </>
              )}
            </div>
          </div>
        
      </ModalContainer>)}

      {showForgotModal && (
        <ForgotPasswordModal
          showForgotModal={showForgotModal}
          setShowForgotModal={setShowForgotModal}
        />
      )}
    </>
  );
};

export default SettingsContainer;
