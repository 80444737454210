import { Popover } from "antd";
import { useState } from "react";
import "./PopoverSelectOptionHolder.scss";
import { ReactComponent as CheckMark } from "../../assets/icons/smallcheckIcon.svg";
import { FilterSelectionType } from "../../pages/Home/types";
import CustomDateRange from "../CustomDateRange/CustomDateRange";

interface PopoverContentHolderType {
  applyRangeValue: (dateInfo: FilterSelectionType, applyTo: string) => void;
  selectOptions: { label: string; value: string | Date[] }[];
  currentTextValue: string;
  setOpenRevenueDatePopOver: (openRevenueDataDatePopOver: boolean) => void;
}

const PopoverSelectOptionHolder = ({
  applyRangeValue,
  selectOptions,
  currentTextValue,
  setOpenRevenueDatePopOver,
}: PopoverContentHolderType) => {
  const [showCustomDatePopover, setShowCustomDatePopover] = useState(false);
  // const [selectedCustomRange, setSelectedCustomRange] = useState<Date[] | null>(
  //   null
  // );
  // const handleApplyCustomRange = () => {
  //   if (selectedCustomRange) {
  //     applyRangeValue(
  //       {
  //         label: "Custom Range",
  //         value: [selectedCustomRange[0], selectedCustomRange[1]],
  //       },
  //       "revenueData"
  //     );
  //     setShowCustomDatePopover(false);
  //   } else {
  //     toast.error("Select valid date range");
  //   }
  // };
  // const handleRangeChange = (dates: any) => {
  //   if (dates) {
  //     setSelectedCustomRange([dates[0]?.toDate(), dates[1]?.toDate()]);
  //   } else {
  //     setSelectedCustomRange(null);
  //   }
  // };

  // const dateRangeRef = useRef<HTMLDivElement>(null);
  return (
    <div className="popover-select-option-container">
      {selectOptions.map((option, id) =>
        option?.label !== "Custom Range" ? (
          <div
            key={id}
            className={`popover-item ${
              currentTextValue === option.label ? "active" : ""
            }`}
            onClick={() => {
              applyRangeValue(option, "revenueData");
              setOpenRevenueDatePopOver(false);
            }}>
            <span className="popover-text">{option.label}</span>
            {currentTextValue === option.label && (
              <span className="w-4 flex items-center">
                <CheckMark />
              </span>
            )}
          </div>
        ) : (
          <Popover
            content={
              <div className="revenue-date-container w-fit relative flex flex-col">
                <CustomDateRange
                  applyRangeValue={applyRangeValue}
                  handleClosePopOver={() => {
                    setShowCustomDatePopover(false);
                    setOpenRevenueDatePopOver(false);
                  }}
                />
              </div>
            }
            trigger="click"
            placement="left"
            open={showCustomDatePopover}
            onOpenChange={setShowCustomDatePopover}
            overlayStyle={{ width: "fit-content" }}>
            <div
              className="popover-item"
              key={id}
              onClick={() => setShowCustomDatePopover(true)}>
              <span className="popover-text">{option.label}</span>
            </div>
          </Popover>
        )
      )}
    </div>
  );
};

export default PopoverSelectOptionHolder;
