import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar, EffectFade } from "swiper/modules";
import { useCallback, useRef, useState } from "react";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightarrow.svg";
import { ReactComponent as Bulb } from "../../assets/icons/bulb.svg";
import { ReactComponent as UpwardArrow } from "../../assets/icons/uparrow.svg";
import { ReactComponent as BlockUpwardArrow } from "../../assets/icons/blockupwardarrow.svg";
import { ReactComponent as BigTrophy } from "../../assets/icons/trophy.svg";
import { ReactComponent as SmallTrophy } from "../../assets/icons/smalltrophy.svg";
import { ReactComponent as UpwardGraphGraphics } from "../../assets/icons/increasinggraph.svg";
import { ReactComponent as DownwardsGraphGraphics } from "../../assets/icons/decreasinggraph.svg";
import { ReactComponent as FlyingPlane } from "../../assets/icons/flyingplane.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

import "./InsightSlider.scss";
import Heading from "../Heading/Heading";
import { formatCurrency } from "../../utils/helper";
import { useQuery } from "react-query";
import { getInsightData } from "../../services/home";
import DataLoadingIndicator from "../DataLoadingIndicator/DataLoadingIndicator";
import { toast } from "react-toastify";
import NoDataIndicator from "../NoDataIndicator/NoDataIndicator";

const InsightSlider = () => {
  const sliderRef = useRef<SwiperRef>(null);
  const sliderPrevButton = useRef(null);
  const sliderNextButton = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);

  const highlightText = (
    sentence: string,
    searchText: string,
    color: string = "black",
    bold: boolean = false
  ) => {
    // Escape special characters in the search text to use in regex
    const escapedText = searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // Create a regex to find the searchText (case-insensitive)
    const regex = new RegExp(`(${escapedText})`, "gi");

    // Replace the found text with a span that includes styling
    const highlightedText = sentence.replace(regex, (match) => {
      const style = `color: ${color}; ${bold ? "font-weight: bold;" : ""}`;
      return `<span style="${style}">${match}</span>`;
    });

    return highlightedText;
  };

  const {
    isLoading: isInsightLoading,
    data: insightData,
    isError: isInsightError,
  } = useQuery(["InsightData"], () => getInsightData(), {
    enabled: true,
    retry: false,
    onSuccess: (res) => {},
    onError: (err: any) => {
      toast.error(
        err?.response?.data?.message || "An error has occurred, please contact system admin!"
      );
    },
  });

  const HighlightFirstWord = (
    text: string = "",
    growthstate: boolean = true
  ) => {
    const phrase = growthstate ? "increase" : "descrease";
    const phraseIndex = text.indexOf(phrase);

    if (phraseIndex === -1) {
      return text;
    }
    const beforePhrase = text.slice(0, phraseIndex);
    const afterPhrase = text.slice(phraseIndex + phrase.length);

    return (
      <>
        {beforePhrase}
        <span
          className={`${
            growthstate ? "first:text-[#049F6F]" : "first:text-[#FF1507]"
          }`}>
          {phrase}
        </span>
        {afterPhrase}
      </>
    );
  };

  const boldenPhrase = (text: string = "", phrase: string = "") => {
    const phraseIndex = text.indexOf(phrase);

    if (phraseIndex === -1) {
      return text;
    }
    const beforePhrase = text.slice(0, phraseIndex);
    const afterPhrase = text.slice(phraseIndex + phrase.length);

    return (
      <>
        {beforePhrase}
        <span className="font-bold">{phrase}</span>
        {afterPhrase}
      </>
    );
  };

  return (
    <div className="sight-slider">
      {isInsightLoading ? (
        <DataLoadingIndicator customStyling="aspect-[1/1.2] bg-white rounded-[20px]" />
      ) : isInsightError ? (
        <NoDataIndicator
          customText="An error occurred, please try again later!"
          customStyling="aspect-[1/1.2] bg-white rounded-[20px]"
        />
      ) : (
        <>
          <Swiper
            ref={sliderRef}
            effect={"fade"}
            fadeEffect={{
              crossFade: true,
            }}
            className="slider"
            loop={true}
            speed={1000}
            autoplay={{
              delay: 20000,
              disableOnInteraction: false,
            }}
            onSlideChange={(swiper: any) => {
              setCurrentIndex(swiper.realIndex);
            }}
            modules={[Scrollbar, Autoplay, EffectFade]}>
            <SwiperSlide
              className={`slider-card ${
                insightData?.data?.gmvReport?.increase ? "" : "decrease-card"
              }`}>
              <Heading
                icon={<Bulb />}
                headingText="Insight"
                headingToolTipText="Insights are based on data from the Intelligence Dashboard and NCAA TSC. "
              />
              <div className="content">
                <p className="title">
                  {insightData?.data?.gmvReport?.title}{" "}
                  <span
                    className={`icon ${
                      insightData?.data?.gmvReport?.increase ? "" : "decrease"
                    }`}>
                    <BlockUpwardArrow className="arrow-icon" />
                  </span>
                </p>
                <p className={`body-one`}>
                  {insightData?.data?.gmvReport?.bodyOne}
                </p>
                <p className="body-two">
                  {insightData?.data?.gmvReport?.bodyTwo}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slider-card">
              <p className="big-trophy">
                <BigTrophy />
              </p>
              <Heading
                icon={<Bulb />}
                headingText="Insight"
                headingToolTipText="Insights are based on data from the Intelligence Dashboard and NCAA TSC. "
              />
              <div className="content">
                <p className="title">
                  {insightData?.data?.flightLeader?.title}
                </p>
                <p className="body-one">
                  {boldenPhrase(
                    insightData?.data?.flightLeader?.bodyOne,
                    insightData?.data?.flightLeader?.textToBolden
                  )}
                </p>
                <p className="body-two">
                  {insightData?.data?.flightLeader?.bodyTwo}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slider-card">
              <Heading
                icon={<Bulb />}
                headingText="Insight"
                headingToolTipText="Insights are based on data from the Intelligence Dashboard and NCAA TSC. "
              />
              <div className="content">
                <p className="sub-title">
                  <SmallTrophy /> Top route
                </p>
                <p className="small-title">
                  {insightData?.data?.topRoute?.title}
                </p>
                {insightData?.data?.topRoute?.values?.map(
                  (
                    value: {
                      name: string;
                      amount: number;
                      percentage: number;
                      decrease: boolean;
                    },
                    id: number
                  ) => (
                    <div key={id} className={`route-stats`}>
                      <p className="name">{value.name}</p>
                      <div className="">
                        {value.name === "Revenue" ? (
                          <p className="amount">
                            {formatCurrency(value?.amount, "currency")}
                          </p>
                        ) : (
                          <p>{value?.amount}</p>
                        )}
                        <p
                          className={`growth-stat ${
                            value?.decrease ? "decrease" : ""
                          }`}>
                          <span>
                            {value?.percentage}%{" "}
                            <UpwardArrow className="arrow-icon" />
                          </span>
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="fly-plane">
                <FlyingPlane />
              </div>
            </SwiperSlide>
            <SwiperSlide
              className={`slider-card ${
                insightData?.data?.passengerTrend?.increase
                  ? ""
                  : "decrease-card"
              }`}>
              <Heading
                icon={<Bulb />}
                headingText="Insight"
                headingToolTipText="Insights are based on data from the Intelligence Dashboard and NCAA TSC. "
              />
              <div className="content">
                <p className="title">
                  {insightData?.data?.passengerTrend?.title}{" "}
                  <span
                    className={`icon ${
                      insightData?.data?.passengerTrend?.increase
                        ? ""
                        : "decrease"
                    }`}>
                    <BlockUpwardArrow className="arrow-icon" />
                  </span>
                  <span className="sub-text">
                    {insightData?.data?.passengerTrend?.bodyOne}
                  </span>
                </p>
                <div className="graphics-container">
                  {insightData?.data?.passengerTrend?.increase ? (
                    <UpwardGraphGraphics className="icon" />
                  ) : (
                    <DownwardsGraphGraphics className="icon" />
                  )}
                  <div className="week-days">
                    <p>Mon</p>
                    <p>Tue</p>
                    <p>Wed</p>
                    <p>Thu</p>
                    <p>Fri</p>
                    <p>Sat</p>
                    <p>Sun</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className={`slider-card ${
                insightData?.data?.flightOperations?.increase
                  ? ""
                  : "decrease-card"
              }`}>
              <Heading
                icon={<Bulb />}
                headingText="Insight"
                headingToolTipText="Insights are based on data from the Intelligence Dashboard and NCAA TSC. "
              />
              <div className="content">
                <p className="title">
                  {insightData?.data?.flightOperations?.title}{" "}
                  <span
                    className={`icon ${
                      insightData?.data?.flightOperations?.increase
                        ? ""
                        : "decrease"
                    }`}>
                    <BlockUpwardArrow className="arrow-icon" />
                  </span>
                </p>
                <p className="body-one">
                  {insightData?.data?.flightOperations?.bodyOne}
                </p>
                <p className="body-two">
                  {insightData?.data?.flightOperations?.bodyTwo}
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="button-holder">
            <button
              ref={sliderPrevButton}
              // @ts-ignore
              onClick={() => handlePrev()}
              className={`h-5 w-5 cursor-pointer hover:bg-[#f6f6f6]  transition-all duration-300 rounded-md`}>
              <LeftArrow />
            </button>
            <div className="scroll-bar">
              {[0, 1, 2, 3, 4].map((activeSlideIndex) => (
                <div
                  key={activeSlideIndex}
                  className={`bar ${
                    currentIndex === activeSlideIndex ? "active" : ""
                  }`}></div>
              ))}
            </div>
            <button
              ref={sliderNextButton}
              onClick={() => handleNext()}
              className={`h-5 w-5 cursor-pointer hover:bg-[#f6f6f6] transition-all duration-300 rounded-md`}>
              <RightArrow />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default InsightSlider;
