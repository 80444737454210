import { Radio, RadioChangeEvent } from 'antd'
import "./CurrencyRadioSelector.scss"

interface CurrencyRadioSelectorType {
    currency:string; setCurrency: (currency: string)=>void
}

const CurrencyRadioSelector = ({currency, setCurrency}: CurrencyRadioSelectorType) => {
    const handleCurrencyChange = (e: RadioChangeEvent) => {
        setCurrency(e.target.value);
      };
  return (
    <Radio.Group onChange={handleCurrencyChange} value={currency} className='radio-button'>
    <Radio value={"naira"}>Naira</Radio>
    <Radio value={"dollar"}>Dollar</Radio>
  </Radio.Group>
  )
}

export default CurrencyRadioSelector