import { alternateRevenueChartDataType, RevenueChartDataType } from "../components/RevenueBreakDownCard/types";

export const formatCurrency = (amount = 0, type = "naira") => {
  // Create Intl.NumberFormat object with Nigerian currency format
  const formatter =
    type === "dollar"
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
        })
      : new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
          minimumFractionDigits: 2,
        });

  // Format the currency
  return formatter.format(amount);
};

export const getPercentageContributionToTotal = (
  numbersList: number[],
  individual: number
) => {
  const total = numbersList.reduce((acc, num) => acc + num, 0);
  return Math.round((individual / total) * 100) || 0;
};
export const getPercentageContributionFromTotal = (
  total: number,
  individual: number
) => {
  return Math.round((individual / total) * 100) || 0;
};

export const separateAndCapitalize = (text: string) => {
  return text
    .replace(/([A-Z][a-z0-9]+)/g, " $1")
    .replace(/([A-Z]+)/g, " $1")
    .trim()
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getFullNameInitials = (fullName: string) => {
  // Split the full name into first and last names
  const names = fullName?.split(" ");
  const firstName = names && names[0];
  const lastName = names?.length > 1 ? names[names.length - 1] : "";

  // Get the first letter of each name
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();

  // Concatenate and return the initials
  return `${firstInitial}${lastInitial}`;
};

// Restructure data key names
export const restructureAirportDataKeyName = (dataList: alternateRevenueChartDataType[]): RevenueChartDataType[] => {
  return dataList.map(data => {
    const { tscDomestic, tscInternational, pscDomestic, pscRegional, ...rest } = data;
    
    return {
      ...rest, 
      tscDom: tscDomestic,
      tscInt: tscInternational,
      pscDom: pscDomestic,
      pscReg: pscRegional,
    };
  });
};

